
import * as React from 'react'
const NewChatIcon = (props) => (
    <svg
    width='17'
    height='17'
    viewBox='0 0 17 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M16.5135 8.25673C16.5135 3.69654 12.8169 0 8.25673 0C3.69654 0 0 3.69654 0 8.25673C0 9.66286 0.351737 10.9864 0.970992 12.1457L0 16.5135L4.36946 15.5433C5.52788 16.1626 6.85144 16.5135 8.25673 16.5135C12.8169 16.5135 16.5135 12.8169 16.5135 8.25673ZM1.65135 8.25673C1.65135 4.60891 4.60891 1.65135 8.25673 1.65135C11.9046 1.65135 14.8621 4.60891 14.8621 8.25673C14.8621 11.9046 11.9046 14.8621 8.25673 14.8621C7.15446 14.8621 6.09595 14.5938 5.14807 14.0868L4.60891 13.7987L2.17482 14.3386L2.71647 11.9062L2.42748 11.3662C1.92052 10.4183 1.65135 9.35901 1.65135 8.25673ZM5.11663 9.31662C5.66492 9.31662 6.10939 8.87214 6.10939 8.32385C6.10939 7.77557 5.66492 7.33109 5.11663 7.33109C4.56834 7.33109 4.12387 7.77557 4.12387 8.32385C4.12387 8.87214 4.56834 9.31662 5.11663 9.31662ZM9.31667 8.3241C9.31667 8.87238 8.87219 9.31686 8.3239 9.31686C7.77562 9.31686 7.33114 8.87238 7.33114 8.3241C7.33114 7.77581 7.77562 7.33133 8.3239 7.33133C8.87219 7.33133 9.31667 7.77581 9.31667 8.3241ZM11.5315 9.31686C12.0798 9.31686 12.5242 8.87238 12.5242 8.3241C12.5242 7.77581 12.0798 7.33133 11.5315 7.33133C10.9832 7.33133 10.5387 7.77581 10.5387 8.3241C10.5387 8.87238 10.9832 9.31686 11.5315 9.31686Z'
      fill='#19C37D'
    />
  </svg>
)
export default NewChatIcon
