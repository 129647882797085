import React from 'react';
import {CardData,MarqueeWrapper,HeaderSections} from '../shared/landingPageComponent';
import { benifitClock,benefitJudgments,benefitQuestion,benifitService ,benifitDrafting,costEffective} from '../../assets/landingPageImgs'
const benefits = [
  {
    title: 'Relevant Answers',
    icon: benifitClock, // replace with actual icon path
    description: 'Get accurate and timely legal answers.',
  },
  {
    title: 'Comprehensive Judgments',
    icon: benefitJudgments, // replace with actual icon path
    description: 'Access a vast database of legal judgments.',
  },
  {
    title: 'Efficient Drafting',
    icon: benifitDrafting, // replace with actual icon path
    description: 'Streamline your legal document drafting.',
  },
  {
    title: 'Interactive Learning',
    icon: benefitQuestion, // replace with actual icon path
    description: 'Engage in interactive legal learning.',
  },
  {
    title: '24/7 Support',
    icon: benifitService, // replace with actual icon path
    description: 'Receive support anytime you need it.',
  },
  {
    title: 'Cost Effective',
    icon: costEffective, // replace with actual icon path
    description: 'Get accurate and timely legal answers.',
  },
  {
    title: 'Relevant Answers',
    icon: benifitClock, // replace with actual icon path
    description: 'Get accurate and timely legal answers.',
  },
  {
    title: 'Comprehensive Judgments',
    icon: benefitJudgments, // replace with actual icon path
    description: 'Access a vast database of legal judgments.',
  },
  {
    title: 'Efficient Drafting',
    icon: benifitDrafting, // replace with actual icon path
    description: 'Streamline your legal document drafting.',
  },
  {
    title: 'Interactive Learning',
    icon: benefitQuestion, // replace with actual icon path
    description: 'Engage in interactive legal learning.',
  },
  {
    title: '24/7 Support',
    icon: benifitService, // replace with actual icon path
    description: 'Receive support anytime you need it.',
  },
]

const Benefits = () => {
  return (
    <div className="mt-32" id="Services">
      <HeaderSections title="Benefits" />
      <div className="xs:hidden md:flex relative items-center w-full overflow-hidden py-5">
        <MarqueeWrapper
          items={benefits}
          renderItem={(benefit, index) => (
            <CardData
              key={index}
              image={benefit.icon}
              title={benefit.title}
              // description={benefit.description}
            />
          )}
        />
      </div>
      <div className="mobile-responsive xs:flex md:hidden">
        <div className="grid xs:grid-cols-2 gap-4 mt-24 py-5">
          {benefits.slice(0, 6).map((benefit, index) => (
            <CardData
              key={index}
              image={benefit.icon}
              title={benefit.title}
              description={benefit.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
