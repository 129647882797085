import * as React from 'react'
const AdvancedSearchIcon = (props) => (
<svg
width='16'
height='23'
viewBox='0 0 25 23'
fill='none'
xmlns='http://www.w3.org/2000/svg'
{...props}
>
<g clipPath='url(#clip0_2277_1010)'>
  <path
    d='M12.7536 0.00285993C16.3393 0.00285993 19.9255 0.00514655 23.5111 1.64776e-06C24.0224 -0.000570008 24.4253 0.147489 24.6464 0.620248C24.8424 1.03813 24.7798 1.41656 24.4545 1.7527C24.38 1.8293 24.3019 1.90247 24.2244 1.97679C21.5833 4.51094 18.9452 7.0468 16.2969 9.57352C16.0949 9.76617 16.024 9.95596 16.0246 10.2218C16.0324 14.1005 16.0312 17.9791 16.0288 21.8578C16.0288 22.6176 15.5091 23.0955 14.7726 22.9846C14.5622 22.9531 14.3304 22.8411 14.1791 22.6976C12.7214 21.3199 11.2793 19.9268 9.83124 18.5394C9.57619 18.2953 9.48084 18.002 9.48143 17.6602C9.48561 15.1443 9.48143 12.6284 9.48799 10.112C9.48799 9.9148 9.42899 9.7776 9.28359 9.63869C6.57574 7.04795 3.87444 4.45034 1.1654 1.86074C0.851945 1.56119 0.679129 1.23821 0.792949 0.812896C0.926435 0.312126 1.30365 0.0251545 1.84713 0.00457489C1.94605 0.000573304 2.04557 0.00285993 2.14509 0.00285993C5.68126 0.00285993 9.21744 0.00285993 12.7536 0.00285993Z'
    fill='#22828E'
  />
</g>
<defs>
  <clipPath id='clip0_2277_1010'>
    <rect
      width='24'
      height='23'
      fill='white'
      transform='translate(0.757812)'
    />
  </clipPath>
</defs>
</svg>

)
export default AdvancedSearchIcon