import * as React from 'react'
const QuizSideIcon = (props) => (
  <svg
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13 5.60117C11.441 5.60117 10.202 4.36211 10.202 2.80313C10.202 1.24414 11.441 0 13 0C14.559 0 15.798 1.23906 15.798 2.79805C15.798 4.35703 14.559 5.60117 13 5.60117ZM26 16.7578C26 18.8398 24.3598 20.4801 22.2777 20.4801H20.4395C18.3574 20.4801 16.7172 18.8398 16.7172 16.7578L20.4395 9.31836H18.6012C17.5602 9.31836 16.7629 8.48047 16.7629 7.48008H14.9246V22.3184C15.6863 22.3184 16.7629 23.1562 16.7629 24.1566H18.6012C19.3629 24.1566 20.4395 24.9945 20.4395 25.9949H5.56055C5.56055 24.9539 6.64219 24.1566 7.39883 24.1566H9.24219C9.24219 23.1156 10.3238 22.3184 11.0805 22.3184H11.1211L11.0805 7.48008H9.24219C9.24219 8.52109 8.4043 9.31836 7.40391 9.31836H5.56562L9.27773 16.7578C9.27773 18.8398 7.6375 20.4801 5.55547 20.4801H3.72227C1.64023 20.4801 0 18.8398 0 16.7578L3.72227 9.31836H1.87891V7.48008H7.43945C7.43945 6.43906 8.27734 5.6418 9.27773 5.6418H16.7172C17.7582 5.6418 18.5555 6.47969 18.5555 7.48008H24.1211V9.31836H22.2828L26 16.7578ZM4.64141 11.2023L1.83828 16.7578H7.39883L4.64141 11.2023ZM24.1617 16.7578L21.3637 11.1973L18.5605 16.7578H24.1617Z'
      fill='white'
    />
  </svg>
)
export default QuizSideIcon
