import React from 'react';
import { bad } from '../../../assets';
const ErrorModal = ({ message, icon, onClose }) => {
  if (!message) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-4 w-80 flex flex-col items-center shadow-lg">
        <div className="xs:text-[32px] md:text-5xl mb-4">
          <img src={bad} alt="bad req" />
          </div> {/* Icon field */}
        <p className="text-center text-gray-800 mb-4">{message}</p> {/* Backend message */}
        <button
          onClick={onClose}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ErrorModal;
