

import * as React from 'react'
const DownloadIcon = (props) => (
    <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    >
    <path
      d='M17.7463 23.9791C17.7764 24.0175 17.8148 24.0486 17.8587 24.0699C17.9025 24.0913 17.9507 24.1024 17.9994 24.1024C18.0482 24.1024 18.0964 24.0913 18.1402 24.0699C18.1841 24.0486 18.2225 24.0175 18.2526 23.9791L22.7526 18.2858C22.9173 18.0768 22.7686 17.7675 22.4994 17.7675H19.5222V4.17104C19.5222 3.99425 19.3776 3.84961 19.2008 3.84961H16.7901C16.6133 3.84961 16.4686 3.99425 16.4686 4.17104V17.7634H13.4994C13.2302 17.7634 13.0816 18.0728 13.2463 18.2818L17.7463 23.9791ZM32.7048 22.5728H30.2941C30.1173 22.5728 29.9727 22.7175 29.9727 22.8943V29.0818H6.02623V22.8943C6.02623 22.7175 5.88159 22.5728 5.7048 22.5728H3.29408C3.1173 22.5728 2.97266 22.7175 2.97266 22.8943V30.8496C2.97266 31.5608 3.54721 32.1353 4.25837 32.1353H31.7405C32.4517 32.1353 33.0262 31.5608 33.0262 30.8496V22.8943C33.0262 22.7175 32.8816 22.5728 32.7048 22.5728Z'
      fill='#13343B'
    />
    </svg>
)
export default DownloadIcon
