import React from 'react';

const HeaderSections = ({ title,Description,descriptionDetails }) => {
  return (
    <div className="text-center">
      <h1 className="text-center xs:text-[32px] md:text-5xl font-bold">{title}</h1>
      <p className="text-center xs:text-[32px] md:text-5xl font-bold">{Description}</p>
      <div className='divider border-2 border-[#0e877d] block m-auto w-20 mb-5'></div>
      <p className={`text-center text-lg mb-6`}>{descriptionDetails}</p>

    </div>
  );
};

export default HeaderSections;
