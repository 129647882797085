
import React, { useState,useContext } from 'react';
import QuizData from './QuizData';
import Catagories from './Catagories';
import '../../styles/quiz.css';
import PopupModal from './PopupModel'; // Import your PopupModal component
import GlobalContext from '../../context/GlobalContext';

function QuizApp() {
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(true);
  const [isQuizActive, setIsQuizActive] = useState(false);
  const { setIsLoading
  } = useContext(GlobalContext);

  const handleConfirm = () => {
    setIsLoading(true)
    setIsPopupModalOpen(false);
    setIsQuizActive(true);
  };

  const handleClose = () => {
    setIsPopupModalOpen(false);
  };

  return (
    <div className="app">
      <div className="sidebar md:block">
        <Catagories />
      </div>
      <div className="quiz-data">
        {isQuizActive && <QuizData />}
      </div>
      <PopupModal
        isOpen={isPopupModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        message="Do you want to attempt the MCQs?"
      />
    </div>
  );
}

export default QuizApp;