import React from 'react'
import lexaVideo from '../../assets/landingPageImgs/legal.mp4'

const LexaVideo = () => {
  const handleError = (event) => {
    console.error('Video failed to load:', event)
  }

  return (
    <div className='w-full bg-black'>
      <div className=' flex justify-center items-center '>
        <video
          className=' md:w-[70%] md:h-[70%] xs:w-auto xs:h-auto'
          controls
          autoPlay
          loop
          onError={handleError}
        >
          <source src={lexaVideo} type='video/mp4' />
        </video>
      </div>
    </div>
  )
}

export default LexaVideo
