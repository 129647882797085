import React, {useState } from 'react';
import Cookies from 'js-cookie';
import { Api } from '../../api';
import ErrorModal from '../../components/shared/Modal/ErrorModal';
const FacebookAuth = ({ authIcon }) => {
  const [error, setError] = useState(null);

  // Handle login status response
  function statusChangeCallback(response) {
    if (response.status === 'connected') {
      handleFacebookLoginSuccess(response?.authResponse?.accessToken);
    } else {
      console.log('User is not authenticated');
    }
  }

  // Function to check login state
  function checkLoginState() {
    window.FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  }

  // Function to handle successful login
  const handleFacebookLoginSuccess = (accessToken) => {
    Api.facebookLogin(accessToken, 'facebook')
      .then((response) => {
        const data = response?.data;
        if (data?.data?.token) {
          Cookies.set('token', data?.data?.token);
          window.location.href = '/';  // Redirect after login
        }
      })
      .catch((error) => {
        const errorMessage= error?.response?.data?.message
        console.log('Facebook login failed:', errorMessage);
        setError(`Facebook login failed : ${errorMessage || 'An error occurred while logging in with Facebook.'}`)
      });
  };
  const closeModal = () => setError(null);

  return (
  <div>
      <button
        onClick={(e) => {
          e.preventDefault();
          window.FB.login(checkLoginState, { scope: 'public_profile,email' });
        }}
      >
        <div className="flex items-center">{authIcon}</div>
      </button>

      <ErrorModal
        message={error}
        onClose={closeModal}
      />
    </div>
  );
};

export default FacebookAuth;
