
import * as React from 'react'
const ContactUsIcons = (props) => (
    <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='20'
    viewBox='0 0 17 20'
    fill='none'
    {...props}
  >
    <path
      d='M9.95866 1H3.12533C2.67225 1 2.23773 1.17998 1.91735 1.50036C1.59698 1.82073 1.41699 2.25526 1.41699 2.70833V16.375C1.41699 16.8281 1.59698 17.2626 1.91735 17.583C2.23773 17.9033 2.67225 18.0833 3.12533 18.0833H13.3753C13.8284 18.0833 14.2629 17.9033 14.5833 17.583C14.9037 17.2626 15.0837 16.8281 15.0837 16.375V6.125M9.95866 1L15.0837 6.125M9.95866 1L9.95866 6.125H15.0837M11.667 10.3958H4.83366M11.667 13.8125H4.83366M6.54199 6.97917H4.83366'
      stroke='#20808D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default ContactUsIcons
