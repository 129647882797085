// components/GoogleAuth.jsx
import React,{useState} from 'react';
import Cookies from 'js-cookie';
import {Api} from '../../api'
import { useGoogleLogin } from '@react-oauth/google';
import ErrorModal from '../../components/shared/Modal/ErrorModal';

const GoogleAuth = ({authIcon}) => {
  const [error, setError] = useState(null);

  
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // console.log("from google", tokenResponse)
      Api.googleLogin(tokenResponse?.access_token, 'google')
      .then((response) => {
        const data = response?.data
        if (data?.data?.token) {
          Cookies.set('token', data?.data?.token);
          window.location.href = '/';  // Redirect after login
        }
      })
      .catch((error) => {
        const errorMessage= error?.response?.data?.message
        console.log('Google login failed:', errorMessage);
        setError(`Google login failed : ${errorMessage || 'An error occurred while logging in with Facebook.'}`)
      });
    }
  });
  const closeModal = () => setError(null);


  return (
    <div>
   <button
      onClick={(e) => {
        e.preventDefault();
        googleLogin();
      }}
    >
      <div className="flex items-center">{authIcon}</div>
    </button>
    <ErrorModal
        message={error}
        onClose={closeModal}
      />
    </div>
 
  );
};

export default GoogleAuth;
