import * as React from 'react'
const LogoutIcon = (props) => (
  <svg
    width='28'
    height='29'
    viewBox='0 0 28 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.502 8.73568C10.5161 6.19815 10.6286 4.82392 11.5251 3.92747C12.5502 2.90234 14.2001 2.90234 17.4999 2.90234L18.6666 2.90234C21.9664 2.90234 23.6163 2.90234 24.6415 3.92747C25.6666 4.9526 25.6666 6.60251 25.6666 9.90234L25.6666 19.2357C25.6666 22.5355 25.6666 24.1854 24.6415 25.2106C23.6163 26.2357 21.9664 26.2357 18.6666 26.2357L17.4999 26.2357C14.2001 26.2357 12.5502 26.2357 11.5251 25.2106C10.6286 24.3141 10.5161 22.9399 10.502 20.4023'
      stroke='#20808D'
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M17.5 14.5693L2.33333 14.5693M2.33333 14.5693L6.41667 11.0693M2.33333 14.5693L6.41667 18.0693'
      stroke='#20808D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default LogoutIcon
