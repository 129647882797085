import React, { useState, useEffect } from "react";
import { bgHero } from '../../assets'
import { useParams, useLocation } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { LexaLogoIcon} from '../../assets/icons'
import { Api } from "../../api";

function Otp() {
  const { email } = useParams()
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState('')
  const email_user = Cookies.get('email')
  const location = useLocation()
  const handleOtpComplete = async () => {
    setLoading(true)
    await Api.otpVerify(email, otp)
      .then((response) => {
        Cookies.set('token', response?.data?.data?.token)
        Cookies.set('showTrialToast', true);
        setLoading(false)
        window.location.href = '/'
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          text: error.response.data.message,
          customClass: {
            confirmButton: 'custom-confirm-button',
          },
        })
        setLoading(false)
      })
  }
  const handleOtpResend = async () => {
    const values = { email: email }
    await Api.resendOTP(values)
      .then((response) => {
        Cookies.remove('email')
        setSeconds(120)
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          text: error.response.data.error,
          customClass: {
            confirmButton: 'custom-confirm-button',
          },
        })
      })
  }

  const [seconds, setSeconds] = useState(120)
  const [checkOTP, setCheckOTP] = useState(false)

  const [formattedTime, setFormattedTime] = useState('02:00')
  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        setFormattedTime(
          `${minutes.toString().padStart(2, '0')}:${remainingSeconds
            .toString()
            .padStart(2, '0')}`
        )
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [seconds])

  useEffect(() => {
    if (location?.state?.name) {
      handleOtpResend()
    }
  }, [])

  return (
    <>
      <div
        className="max-w-screen max-h-screen xs:min-h-screen bg-no-repeat bg-cover flex justify-center items-center bg-black"
        style={{ backgroundImage: `url(${bgHero})` }}
      >
        <div
          className='xs:w-[95%] sm:w-[75%] md:w-[60%] lg:w-[45%] xl:w-[35%] 
        2xl:w-[40%] 3xl:w-[40%] h-[95vh] flex flex-col
         md:py-[30px] 2xl:py-[30px] justify-between items-center
           rounded-[55px]
           border-r-2 border-l-2 border-[#0c1c1e] 
           shadow-[0_0px_4px_0px_rgba(0,0,0,0.25)]'
        >
          <div className="w-full flex flex-col items-center">
            <div className="  md:w-[100%] mt-[15px] md:mt-[-5px]  gap-3 flex flex-col items-center justify-center md:h-[75px] 2xl:w-auto 2xl:h-auto 3xl:w-[500px] 4xl:w-[800px] 4xl:mt-10">
            <LexaLogoIcon/>
            </div>
            <form className=' text-white xs:w-[90%] md:mt-5 mt-[10vh] flex flex-col items-center justify-center it sm:w-[80%] md:w-[70%] lg:w-[70%] xl:w-[65%] 2xl:w-[60%]'>
              <h1 className='text-center mt-[3vh] mb-[2vh] md:text-[4vh] 2xl:text-[3vh]'>
                Enter OTP
              </h1>

              <OtpInput length={4} setOtp={setOtp} setCheckOTP={setCheckOTP} />
              {checkOTP && (
                <p className='font-[400] text-[12px] 2xl:text-[14px] text-red-600 pt-3 '>
                  * Enter the OTP
                </p>
              )}
              {/* {otp ? "" : <p>Enter the otp</p>} */}
              <div className='flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500 mt-4'>
                {seconds === 0 ? (
                  <p
                    className='flex flex-row items-center text-blue-600 cursor-pointer'
                    onClick={handleOtpResend}
                  >
                    Resend
                  </p>
                ) : (
                  <>
                    <p>Resend code in</p>
                    <p className='flex flex-row items-center text-red-500 cursor-pointer'>
                      {formattedTime}
                    </p>
                  </>
                )}
              </div>
              <div className=' w-full flex justify-center mt-[30px] 4xl:pt-[50px]'>
                <button
                  type='button'
                  onClick={otp ? handleOtpComplete : () => setCheckOTP(true)}
                  className='flex justify-center font-[400] items-center bg-[#0c1f21] w-full h-[45px] 3xl:w-[220px] 3xl:h-[65px] 4xl:w-[280px] 4xl:h-[120px] 3xl:text-2xl 4xl:text-[25px] rounded-[10px] text-white text-[16px]'
                >
                  {loading ? (
                    <RotatingLines
                      strokeColor='white'
                      strokeWidth='2'
                      animationDuration='0.75'
                      width='25'
                      visible={true}
                    />
                  ) : (
                    <>Sign Up with OTP</>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Otp

function OtpInput({ length, setOtp, setCheckOTP }) {
  const inputs = Array.from({ length }, (_, index) => React.createRef())
  const handleInputChange = (event, index) => {
    const inputElement = event.target
    const nextInput = inputs[index + 1]?.current

    if (inputElement.value !== '' && nextInput) {
      nextInput.focus()
    }

    const otpValue = inputs.reduce(
      (acc, input) => acc + input.current.value,
      ''
    )
    setOtp(otpValue)
    setCheckOTP(false)
  }

  const handleInputKeyDown = (event, index) => {
    const inputElement = event.target
    const previousInput = inputs[index - 1]?.current

    if (
      event.key === 'Backspace' &&
      inputElement.value === '' &&
      previousInput
    ) {
      previousInput.focus()
    }
  }

  const handlePaste = (event, index) => {
    event.preventDefault()
    setCheckOTP(false)
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')
    const pastedCharacters = pastedData.split('')
    for (let i = 0; i < length; i++) {
      if (i < pastedCharacters.length) {
        inputs[i].current.value = pastedCharacters[i]
      } else {
        inputs[i].current.value = ''
      }
    }
    const otpValue = inputs.reduce(
      (acc, input) => acc + input.current.value,
      ''
    )
    setOtp(otpValue)
  }

  return (
    <div className='flex items-center justify-center gap-3'>
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          type='text'
          maxLength='1'
          pattern='[0-9]'
          inputMode='numeric'
          ref={inputs[index]}
          onChange={(e) => handleInputChange(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          onKeyDown={(e) => handleInputKeyDown(e, index)}
          className='xs:w-[55px] xs:h-[50px] md:w-[60px] md:h-[60px] 2xl:w-[80px] 2xl:h-[80px] bg-[#D9D9D9] text-center  outline-none rounded-xl border-[1px] border-[#D9D9D9] text-lg  focus:bg-transparent focus:border-[#20808D]'
        />
      ))}
    </div>
  )
}
