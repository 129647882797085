/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import '../styles/general.css'
import { ThreeDots } from 'react-loader-spinner'
import GlobalContext from '../context/GlobalContext'
import {
  SocketContext,
  socketMessageCommands,
} from '../context/SocketContext.jsx'
import { sendIcon, sendIconActive} from '../assets'

function Input(props) {
  const {
    setChatData,
    setChatLoading,
    setErrorMessage,
    setInputQuery,
    inputQuery,
    setSendBtnLoading,
    sendBtnLoading,
    selectedTab,
    selectChatID,
    setIsAwaitingResponse,
  } = useContext(GlobalContext)
  const { sendSocketCommand, sendSocketCommandForHistory } = useContext(SocketContext)



  useEffect(() => {
    if (selectChatID) {
      sendSocketCommandForHistory({
        command: socketMessageCommands.fetchMessages,
        chat_id: selectChatID,
      })
    }
  }, [selectChatID])

  const handleSocketSubmit = (e) => {
    e.preventDefault()
    setIsAwaitingResponse(true) //adding this line of state
    setErrorMessage('')
    props.setDebugMessage('')
    const trimmedQuery = inputQuery.trim()
    if (trimmedQuery !== '') {
      const messageWithLineBreaks = trimmedQuery.replace(/\n/g, '\n')
      setIsAwaitingResponse(true) //adding this line of state

      if (selectChatID) {
        sendSocketCommand({
          command: socketMessageCommands.newMessage,
          chat_id: selectChatID,
          message: inputQuery,
        })
      } else {
        sendSocketCommand({
          command: socketMessageCommands.newChat,
          name: inputQuery.slice(0, 20) + '...',
          message: inputQuery,
        })
        sendSocketCommand({ command: socketMessageCommands.allChats })
      }
      setSendBtnLoading(true)
      setChatLoading(true)
      setChatData([{ role: 'user', content: messageWithLineBreaks }])
      setInputQuery('')
    } else {
      setSendBtnLoading(false)
      setChatLoading(false)
    }
  }
  const handleChange = (e) => {
    setInputQuery(e.target.value)
  }
  const autoResize = (e) => {
    e.target.style.height = '24px'
    e.target.style.height = e.target.scrollHeight + 'px'
  }

  return (
    <>
      <form
        id='input-container'
        style={{
          width: selectedTab === 'Judgement' ? '85%' : '96%',
        }}
        className='dark:bg-white dark:text-black'
      >
        <textarea
          className='custom-textarea dark:bg-white text-black outline-none '
          type='text'
          placeholder='Lexa, how can I help you...'
          onInput={autoResize}
          value={inputQuery}
          onChange={(e) => handleChange(e)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.stopPropagation()
              if (inputQuery && !sendBtnLoading) {
                handleSocketSubmit(e)
              }
            }
          }}
          style={{ height: `${!inputQuery && '45px'}` }}
        />
        {sendBtnLoading ? (
          <ThreeDots
            height='30'
            width='30'
            radius='9'
            color='gray'
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            wrapperClassName=''
            visible={true}
          />
        ) : (
          <button type='submit'>
            <>
              {inputQuery ? (
                <img
                  src={sendIconActive}
                  alt='sending'
                  onClick={
                    inputQuery && !sendBtnLoading
                      ? handleSocketSubmit
                      : (e) => e.preventDefault()
                  }
                />
              ) : (
                <>
                  <img
                    src={sendIcon}
                    alt='sending'
                    onClick={(e) => e.preventDefault()}
                  />
                </>
              )}
            </>
          </button>
        )}
      </form>
    </>
  )
}

export default Input