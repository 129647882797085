import React from 'react';

function StatuteSkeleton() {
  return (
    <div className="flex flex-col border  bg-white shadow rounded-lg overflow-hidden my-4">
      {/* Skeleton for the title and description */}
      <div className="p-4 flex flex-col flex-grow  animate-pulse">
        <div className="bg-gray-300 h-6 w-3/4 mb-2 rounded"></div>
        <div className="bg-gray-300 h-4 w-1/2 mb-4 rounded"></div>
      </div>
      
      {/* Skeleton for the footer (download button) */}
      <div className="flex justify-between items-center border-t-2 ">
        <div className="p-4 animate-pulse">
          <div className="bg-gray-300 h-6 w-3/4 rounded"></div>
        </div>
        <div className="p-[36px] bg-gray-300 animate-pulse">
        </div>
      </div>
    </div>
  );
}

export default StatuteSkeleton;
