import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { GlobalProvider } from './context/GlobalContext'
import Routing from './routing/Routing'
import 'typeface-roboto'
import SocketContextProvider from './context/SocketContext.jsx'
import { Provider } from 'react-redux' // Import the Redux Provider
import { store } from './components/Blogs/redux/store' // Ensure this path is correct and case-sensitive

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css'

function App() {
  return (
    <>
      <Provider store={store}>
        <GlobalProvider>
          <SocketContextProvider>
            <Routing />
          </SocketContextProvider>
        </GlobalProvider>
      </Provider>
    </>
  )
}
export default App
