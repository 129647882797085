
import * as React from 'react'
const TermsConditionsIcon = (props) => (
    <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
  >
    <g clipPath='url(#clip0_1414_5448)'>
      <path
        d='M3.41699 17.9844V12.0052M3.41699 8.58854V2.60938M10.2503 17.9844V10.2969M10.2503 6.88021V2.60938M17.0837 17.9844V13.7135M17.0837 10.2969V2.60938M0.854492 12.0052H5.97949M7.68783 6.88021H12.8128M14.5212 13.7135H19.6462'
        stroke='#20808D'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1414_5448'>
        <rect
          width='20.5'
          height='20.5'
          fill='white'
          transform='translate(0 0.046875)'
        />
      </clipPath>
    </defs>
  </svg>
)
export default TermsConditionsIcon
