import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import GlobalContext from '../context/GlobalContext'
import Cookies from 'js-cookie'
import {
  ContactUsIcons,
  TermsConditionsIcon,
  PrivacyPolicyIcon,
  LogoutIcon,
  RefundPolicyIcon,
} from '../assets/icons'
import ProfileIndicator from './shared/ProfileIndicator'
import user from '../assets/user.png'
import { handleLogout } from '../utilities/Validation'

const userEmail = Cookies.get('email')
const authorizedEmail = ['adeelhaider1541@gmail.com', 'kashafshah804@gmail.com']
const blogItem = {
  id: 1,
  text: 'Add Blogs',
  icon: <ContactUsIcons />,
  href: '/blogs',
}
const baseItems = [
  {
    id: 2,
    text: 'Terms and Conditions',
    icon: <TermsConditionsIcon />,
    href: '/terms-and-conditions',
  },
  {
    id: 3,
    text: 'Privacy Policy',
    icon: <PrivacyPolicyIcon />,
    href: '/privacy-policy',
  },
  {
    id: 4,
    text: 'Refund Policy',
    icon: <RefundPolicyIcon />,
    href: '/refund-policy',
  },
  {
    id: 5,
    text: 'Payment',
    icon: <PrivacyPolicyIcon />,
    href: '/payment',
  },
]

const first_box_items = authorizedEmail.includes(userEmail)
  ? [...baseItems, blogItem]
  : baseItems
function Sidebar({ show, setShow }) {
  const sidebarRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const { userInfo, themeToggle, toggleDarkMode } = useContext(GlobalContext)

  const closeSidebar = () => {
    setIsVisible(false)
    setTimeout(() => {
      setShow(false)
    }, 700)
  }

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      closeSidebar()
    }
  }

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setIsVisible(true)
      }, 300)
    }

    document.addEventListener('mousedown', handleClickOutside, true)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true)
    }
  }, [show])

  return (
    <div
      ref={sidebarRef}
      id='sidebar'
      className={`fixed top-0 left-0 transform transition-transform duration-700 ease-in-out z-[100001] h-fill bg-[#f3f3ee] shadow-lg sm:mt-[72px] xs:mt-[55px] flex flex-col justify-between ${
        isVisible ? 'translate-x-0' : '-translate-x-full'
      } lg:w-[18.5%] sm:w-[23.5%] xs::w-[72%]  overflow-y-auto`}
    >
      {/* Profile Box */}
      <div className='bg-[#121212] py-2 flex flex-col items-center rounded-b-lg'>
        <ProfileIndicator profile={user} />
        <h5 className='text-white font-semibold text-lg mt-2'>
          {userInfo?.first_name} {userInfo?.last_name}
        </h5>
        {userInfo?.email && (
          <p className='text-white text-xs xl:text-sm break-words'>
            {userInfo?.email}
          </p>
        )}

        {/* Dark Mode Toggle */}
        <div className='my-1'>
          <div className='text-white text-xs font-medium ml-3'>Dark Mode</div>
          <div
            onClick={toggleDarkMode}
            className='flex items-center cursor-pointer mt-1'
          >
            <div
              className={`w-[82px] flex items-center rounded-full p-1 transition-all duration-300 ${
                themeToggle ? 'bg-green-400' : 'bg-white'
              }`}
            >
              <div
                className={`h-6 w-6 rounded-full bg-[#22828e] shadow-md transform transition-all duration-300 ${
                  themeToggle ? 'translate-x-4' : 'translate-x-0'
                }`}
              ></div>
              <div
                className={`text-sm font-medium px-2 ${
                  themeToggle
                    ? 'text-white order-first'
                    : 'text-black order-last'
                }`}
              >
                {themeToggle ? 'ON' : 'OFF'}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* First Box (Links) */}
      <div className='flex-grow w-full pb-3'>
        {first_box_items.map((data) => (
          <NavLink
            key={data.id}
            to={data.href}
            className='flex items-center py-3 px-5 border-b border-gray-200 hover:bg-white'
          >
            <div className='text-lg'>{data.icon}</div>
            <span className='ml-3 font-semibold text-sm text-black'>
              {data.text}
            </span>
          </NavLink>
        ))}
      </div>

      {/* Logout */}
      <div className='w-full'>
        <div
          onClick={handleLogout}
          className='absolute bottom-0 flex items-center py-3 px-5 w-full cursor-pointer hover:bg-white'
        >
          <LogoutIcon />
          <span className='ml-3 font-semibold text-sm text-black'>Logout</span>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
