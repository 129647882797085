import React, { Fragment, useState, useContext, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import ErrorMessages from '../../staticData/errors.json'
import Input from '../../components/shared/Input'
import { Dialog, Transition } from '@headlessui/react'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import FormErrors from '../../components/shared/FormErrors'
import SubmitButtonLogin from '../../components/shared/SubmitButtonLogin'
import GoogleAuth from './GoogleAuth'
import FacebookAuth from './FacebookAuth'
import '../../styles/general.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  LexaLogoIcon,
  SigninStarIcon,
  LoginMessageIcon,
  AuthLinkedIn,
  AuthGoogleIcon,
  AuthFacebookIcon,
} from '../../assets/icons'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../components/firebaseAnalytics/Firebase'
import Turnstile from 'react-turnstile'
import GlobalContext from '../../context/GlobalContext'
import { Api } from '../../api'
import { LoginSchema,ForgotSchema } from '../../utilities/Validation'

function Login() {
  const { recaptchaToken, setRecaptchaToken, setIsOnTrial } =
    useContext(GlobalContext)
  const Navigate = useNavigate()
  const location = useLocation()
  const [showPassword, setShowPassword] = useState(false)
  const [show, setShow] = useState(false)
  const [rememberMe, setRememberMe] = useState(false) // New state for Remember me
  const [loading, setLoading] = useState(false)
  const key = process.env.REACT_APP_RECAPTCHA_KEY
  const queryParams = new URLSearchParams(location.search)
  const redirect = queryParams.get('redirect')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const error = params.get('error')

    if (error) {
      toast.error(decodeURIComponent(error), {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }, [location])

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail')
    if (savedEmail) {
      initialValues.email = savedEmail
      setRememberMe(true)
    }
  }, [])

  const initialValues = {
    email: '',
    password: '',
  }
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: LoginSchema ,
      onSubmit: async (values, action) => {
        if (!recaptchaToken) {
          Swal.fire({
            icon: 'error',
            title: 'Please verify you are not a robot',
          })
          return
        }
        setLoading(true)
        await Api.login(values)

          .then((response) => {
            Cookies.set('token', response?.data?.data?.token)
            Cookies.set('trail', response?.data?.data?.subscription?.on_trial)
            Cookies.set(
              'subscription',
              response?.data?.data?.subscription?.subscription
            )
            Cookies.set(
              'subscriptionPlan',
              response?.data?.data?.profile?.subscription_plan?.for_student
            )
            const userEmail = response?.data?.data?.profile?.email
            const trial = response?.data?.data?.subscription?.on_trial
            if (rememberMe) {
              localStorage.setItem('rememberedEmail', values.email) // Save email if Remember me is checked
            } else {
              localStorage.removeItem('rememberedEmail') // Remove email if Remember me is unchecked
            }
            if (redirect === 'payment') {
              setLoading(false)
              window.location.href = '/payment'
            } else {
              setLoading(false)
              window.location.href = '/ChatBot'
            }
            setIsOnTrial(trial)
            logEvent(analytics, 'login', {
              method: 'EmailAndPassword',
              route_name: window.location.pathname,
              username: userEmail,
            })
            Cookies.set('email', values.email)
            action.resetForm()
          })
          .catch((error) => {
            setLoading(false)

            logEvent(analytics, 'login_failure', {
              error: 'Login Failed',
            })

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text:
                error.response.data.message || 'An unexpected error occurred',
              customClass: {
                confirmButton: 'custom-confirm-button',
              },
            }).then((response) => {
              if (
                error.response.data.message ===
                'User is not verified. Please verify OTP through signup'
              )
                Navigate(`/verify-otp/${values?.email}`)
              else console.log('error', error.response.data.message)
            })
          })
      },
    })

  const onChangeRecaptcha = (token) => {
    setRecaptchaToken(token)
  }
  return (
    <>
      <div className='custom-background flex justify-center items-center bg-center bg-cover bg-no-repeat py-3'>
        <div className='flex border-1 border-[#464646] md:w-[48%] lg:w-[40%] xs:w-fit rounded-lg'>
          <div className='relative md:p-8 pb-1 xs:p-2  xs:px-[5px] w-full rounded-lg shadow-2xl'>
            <Link
              to='/register'
              className='xs:hidden md:block bg-[#0D9488] text-white text-lg font-semibold py-2 px-4 rounded-t-md cursor-pointer absolute left-[36px] top-[20%] transform  -translate-x-full -rotate-90'
            >
              Sign Up
            </Link>
            <span className='flex justify-center mb-3'>
              <LexaLogoIcon />
            </span>
            <div className='headings'>
              <h1 className='text-2xl font-semibold text-white mb-3'>
                Sign in
              </h1>
              <span className='flex '>
                <SigninStarIcon />
                <p className='text-white ml-2 mt-1 text-[14px] '>
                  Introducing Lexa 2.0: Your Ultimate Legal Companion
                </p>
              </span>
            </div>
            <form
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault() // Prevent the default form submission
                  handleSubmit() // Call the handleSubmit function
                }
              }}
              onSubmit={handleSubmit}
              className='space-y-6'
            >
              <div className='relative'>
                <Input
                  type='text'
                  placeholder='Enter Your Email Address'
                  name='email'
                  text='Email Address'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errors={errors.email && touched.email ? errors.email : null}
                  className='pr-10'
                />
                <div className='absolute right-3 top-[70%] transform -translate-y-1/2 cursor-pointer text-gray-400'>
                  <LoginMessageIcon />
                </div>
              </div>
              <p className='mb-0 mt-1 text-sm'>
                {errors.email && touched.email ? (
                  <FormErrors error={errors.email} />
                ) : null}
              </p>

              <div className='relative'>
                <div className='flex items-center'>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Password'
                    name='password'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    text='Password'
                    errors={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                  />
                  {showPassword ? (
                    <AiFillEyeInvisible
                      className='absolute right-3 md:top-[46px] xs:top-[64px] inset-y-0 flex items-center cursor-pointer text-gray-400'
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <AiFillEye
                      className='absolute right-3 md:top-[46px] xs:top-[64px] inset-y-0 flex items-center cursor-pointer text-gray-400'
                      onClick={() => setShowPassword(true)}
                    />
                  )}
                </div>
                <p className='mb-0 mt-1 text-sm'>
                  {errors.password && touched.password ? (
                    <FormErrors error={errors.password} />
                  ) : null}
                </p>
              </div>

              <div className='flex items-center justify-between'>
                <label className='flex items-center text-sm text-white'>
                  <input
                    type='checkbox'
                    className='form-checkbox h-4 w-4'
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <span className='ml-2'>Remember me</span>
                </label>
                <Link
                  to='/forgotPassword'
                  className='text-sm text-blue-500 hover:underline'
                >
                  Forgot password?
                </Link>
              </div>
              <div className='mt-3'>
                <Turnstile
                  sitekey={key} // Replace with your actual site key
                  onVerify={onChangeRecaptcha}
                  options={{ theme: 'light' }} // You can set theme to 'light' or 'dark'
                />
              </div>
              <div className='divider flex flex-row text-white'>
                <p className='-mt-3'>or</p>
                <span className='border-1 h-[2px] border-[#A4A4A4] opacity-10 w-full ml-2'></span>
              </div>
              <span className='flex flex-row gap-x-2 mt-0'>
                <GoogleAuth authIcon={<AuthGoogleIcon />} />
                <FacebookAuth authIcon={<AuthFacebookIcon />} />
              </span>
              <SubmitButtonLogin
                handleSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }}
                loading={loading}
                title='Sign in'
              />
            </form>
            <p className='mt-2 text-center text-white text-sm'>
              No account?{' '}
              <Link to='/register' className='text-[#0D9488] hover:underline'>
                Sign up
              </Link>
            </p>
          </div>
        </div>
        <ToastContainer position='bottom-right' autoClose={5000} />
      </div>
      <ForgotPasswordModal show={show} setShow={setShow} />
    </>
  )
}

export default Login


function ForgotPasswordModal(props) {
  const cancelButtonRef = React.useRef(null)
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [loading, setLoading] = useState(false)
  const initialValues = {
    email: '',
  }
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: ForgotSchema,
      onSubmit: async (values, action) => {
        setLoading(true)
        await axios
          .post(`${BASE_URL}/user/send-reset-password-email/`, {
            email: values.email,
          })
          .then((response) => {
            action.resetForm()
            props.setShow(false)
            setLoading(false)
            Swal.fire({
              icon: 'success',
              text: response.data.msg,
              customClass: {
                confirmButton: 'custom-confirm-button',
              },
            })
          })
          .catch((error) => {
            setLoading(false)
            const errorMessage = getErrorMessage(error.response.status)
            if (errorMessage) {
              Swal.fire({
                icon: 'error',
                text: errorMessage,
                customClass: {
                  confirmButton: 'custom-confirm-button',
                },
              })
            }
          })
      },
    })

  return (
    <>
      <Transition.Root show={props.show} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={() => props.setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>
          <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
            <div className=' flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl px-4 py-4 transition-all xl:w-[35%] 3xl:w-[30%]'>
                  <div className='w-full bg-white flex flex-col items-center '>
                    <Dialog.Title
                      as='h1'
                      className=' mb-[20px] text-3xl 3xl:text-6xl 4xl:text-[100px] 4xl:mb-[30px]'
                    >
                      Forgot Password
                    </Dialog.Title>
                    <div className='mt-2 w-full'>
                      <Input
                        type='text'
                        placeholder='Enter Your Email'
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors.email}
                      />
                      <p>
                        {errors.email && touched.email ? (
                          <small className='text-red-600 3xl:text-xl 4xl:text-[35px]'>
                            {errors.email}
                          </small>
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className='flex items-center justify-end py-3'>
                    <button
                      type='button'
                      onClick={() => props.setShow(false)}
                      ref={cancelButtonRef}
                      className='me-2 outline-none flex justify-center items-center bg-gray-400 md:w-[120px] 2xl:w-[130px] h-[40px] 2xl:h-[50px] 3xl:w-[180px] 3xl:h-[65px] 4xl:w-[280px] 4xl:h-[120px] 3xl:text-2xl 4xl:text-[35px] rounded-[150px] text-white font-medium text-[17px]'
                    >
                      Cancel
                    </button>
                    <SubmitButtonLogin
                      title='Submit'
                      handleSubmit={handleSubmit}
                      loading={loading}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

function getErrorMessage(statusCode) {
  const errorItem = ErrorMessages.find((item) => item[statusCode.toString()])
  return errorItem
    ? errorItem[statusCode.toString()]
    : 'An unknown error occurred.'
}
