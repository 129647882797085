import React from 'react'
import { HeaderSections } from '../shared/landingPageComponent'

function About() {
  return (
    <div>
      <section id='About' className='about-section mt-80'>
        <div className='container'>
          <div className='row '>
            <div className='col-md-8 offset-md-2'>
              <div className='heading text-center pt-4' data-aos='fade-right'>
                <HeaderSections
                  title='About Us'
                  descriptionDetails =' Lexa revolutionizes legal assistance in Pakistan with AI,
                    offering lawyers and law students direct access to a
                    comprehensive suite of legal resources for simplified
                    research and study, available anytime, anywhere."'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default About
