
import * as React from 'react'
const IconAi = (props) => (
    <svg
    width='46'
    height='46'
    viewBox='0 0 46 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='46' height='46' fill='#13343B' />
    <path
      d='M26.8128 35.6283H24.6418V18.752H20.9655V35.6283H18.7944V38.3493H13.0918V39.9993H20.9655H24.6418H32.5155V38.3493H26.8128V35.6283Z'
      fill='white'
    />
    <path
      d='M33.9921 14.1797H11.2974L6 25.2376C6.28947 27.9297 8.54737 30.0139 11.2974 30.0139C16.0158 30.0139 16.6237 25.2376 16.6237 25.2376L12.4263 16.4955H32.8921L28.6947 25.2376C28.9842 27.9297 31.2421 30.0139 33.9921 30.0139C38.7105 30.0139 39.3184 25.2376 39.3184 25.2376L33.9921 14.1797ZM11.2974 28.6244C9.82105 28.6244 8.54737 27.8428 7.85263 26.627H14.7131C14.0474 27.8139 12.7737 28.6244 11.2974 28.6244ZM7.53421 25.2376L11.2974 17.3928L15.0895 25.2376H7.53421ZM33.9631 28.6244C32.4868 28.6244 31.2131 27.8428 30.5184 26.627H37.3789C36.7131 27.8139 35.4394 28.6244 33.9631 28.6244ZM30.2289 25.2376L33.9921 17.3928L37.7842 25.2376H30.2289Z'
      fill='white'
    />
    <path
      d='M24.3235 12.5289L25.7998 9.46052L24.3235 7H21.3708L19.8945 9.46052L21.3708 12.5289H24.3235Z'
      fill='white'
    />
  </svg>
)
export default IconAi
