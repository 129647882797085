// //************************* KEY Constants *******************************//
// export const mapApiKey = 'AIzaSyDtMuqTLh2BPqs3JVepGytFmCzshOdEcQg';

// //************************* URL Constants *******************************//

// Server Base URL
// For Production
// export const baseUrl = 'https://api.luxelocker.com/v1';
export const baseUrl = 'https://backend.lexa.lawyer/api';

// // BASE URL FOR UNTS AND CAMPUSES
// export const UNITBASEURLFORWEB = 'https://staging.luxelocker.com/details-unit/';
export const BLOGURLFORWEB = 'https://backend.lexa.lawyer/blogs/';
// // export const BLOGURLFORWEB = 'https://staging.luxelocker.com/blogs/';
// export const CAMPUSBASEURLFORWEB =
//   'https://staging.luxelocker.com/details-campus/';

//************************* End Points Constants *******************************//
export const endPoints = {
  // AUTH
  login: 'login/',

  // Forgot Passsword
  forgotPassword: 'forgot-password/',

  // Change Password
  changePassword: 'profile/change-password/',


  // Get Blogs Page List
  blogList: 'blogs/blog-post/',

  // Add New Blog
  addNewBlog: 'blogs/blog-post/',
//show blogs on blog page//

  fetchBlogsListPage: 'blogs/web/blog-post-listing/',
  // Correct the function name if it was a typo
fetchSingleBlogPage: (slug) => `blogs/web/blog-post-detail/${slug}/`,

  // fetchSingeBlogPage: (slug) => `blogs/web/blog-post-detail/${slug}/`,

  // Add New Blog Images Upload
  addNewBlogImagesUpload: (id) => `blogs/blog-image-upload/${id}/`,

  // Add New Blog Images Upload
  singleBlogPageDetail:  'blogs/blog-post',

  // Upload Blog
  updateBlog: (id) => `blogs/blog-post/${id}`,

  // Delete Blog Page
  deleteBlogPage: (id) => `blogs/blog-post/${id}/`,

  // Delete Blog Images
  deleteBlogImages:'blogs/blog-image-delete/',

  // Delete Blog Images
  updateVideoControls: (id) => `blogs/blog-image-upload/${id}/`,

  fetchTagsList: `blogs/web/blogs-tags/`,


  // Tags Listing
  tagsList: 'blogs/admin-tags-list/',
};
