import React from "react";
import '../../styles/inputautofil.css'
function Input(props) {
  return (
    <>
      <div className="relative w-full">
        <label
          htmlFor="small_outlined"
          className=" text-white text-[21px] 2xl:text-[16px] md:text-sm mb-2 ml-2 "
        >
          {props.text}
        </label>
        <input
          type={props.type}
          autoComplete="off"
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          id={props.id}
          style={{ color: 'white' }}
          className={`rounded-[8px] w-[100%] placeholder:text-[12px] placeholder:text-white placeholder:font-[400] py-[12px] 4xl:mb-2 text-[2vh] 3xl:border-[2px] bg-transparent pl-3 pr-9 text-white border-[1px] border-[#13343b] outline-none focus:border-[#20808d]`}
        />
      </div>
    </>
  );
}

export default Input;
