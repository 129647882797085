import * as Yup from 'yup'
import Cookies from 'js-cookie'

export const handleLogout = () => {
  Cookies.remove('token')
  window.location.href = '/login'
}
//for chatbot text rederening compoennt ReactMarkDown Libaray
export const customComponents = {
  // Existing customizations
  div: ({ node, ...props }) => <div className='text-center' {...props} />,
  h1: ({ node, ...props }) => (
    <h1 className='text-lg font-medium my-1 dark:text-white' {...props} />
  ),
  h2: ({ node, ...props }) => (
    <h2 className='text-base font-normal my-1  dark:text-white' {...props} />
  ),
  h3: ({ node, ...props }) => (
    <h3 className='text-base font-medium my-1 dark:text-white' {...props} />
  ),
  h4: ({ node, ...props }) => (
    <h4 className='text-base font-medium my-1 dark:text-white' {...props} />
  ),
  h5: ({ node, ...props }) => (
    <h5 className='text-base font-normal my-1 dark:text-white' {...props} />
  ),
  strong: ({ node, ...props }) => (
    <strong className='font-medium  dark:text-white' {...props} />
  ),
  p: ({ node, ...props }) => (
    <p
      className='md:text-[16px] xs:text-[16px] mb-0  dark:text-white'
      {...props}
    />
  ),
  // Customizing list items
  li: ({ node, ...props }) => (
    <li className='list-disc ml-5  dark:text-white text-[16px]' {...props} />
  ),
  ul: ({ node, ...props }) => <ul className='mb-0' {...props} />,
}

//Clean Text

export const cleanText = (text) => {
  return text.replace(/\*\*[^\*]+\*\*/g, '').trim()
}

// removeHtmlTags

export const removeHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

//addSpacesToSentence///

export function addSpacesToSentence(sentence) {
  return sentence.replace(/([a-z])([A-Z])/g, '$1 $2')
}

/// handleViewPdf///
export const handleViewPdf = (judgment, setPdfUrl, setOpenModal) => {
  if (!judgment || !judgment.document_url) {
    console.error('Invalid judgment data provided')
    return
  }

  const httpsUrl = convertS3UrlToHttps(judgment.document_url)
  setPdfUrl(httpsUrl) // Update PDF URL state
  setOpenModal(true) // Open the modal
}

//summary visible function ///
export const summaryVisible = []
export const removeSummaryKeywords = (text) => {
  let result = text.replace(/Summary:\s*/gi, '').trim()
  result = result.replace(/Summary/gi, '').trim()
  return result
}

// handleCopyClick///
export const handleCopyClick = (
  content,
  index,
  chatData = [],
  summaryVisible = [],
  setCopySuccessArray,
  copySuccessArray = []
) => {
  let copyContent = removeHtmlTags(content) // Clean the content
  if (summaryVisible[summaryVisible.length - 1]) {
    const judgementData =
      chatData[index + 1]?.role === 'judgement'
        ? chatData[index + 1]
        : chatData[index - 1]?.role === 'judgement'
        ? chatData[index - 1]
        : null

    if (judgementData) {
      const judgements = JSON.parse(judgementData.content).judgements
      const updatedSummary = judgements
        .filter((judgment) => judgment.summary.trim() !== '')
        .map((judgment) => removeSummaryKeywords(judgment.summary))
        .join('\n\n')

      copyContent += `\n\n${updatedSummary}`
    }
  }

  // Copy the prepared content to the clipboard
  if (copyContent) {
    navigator.clipboard
      .writeText(copyContent)
      .then(() => {
        const updatedCopySuccessArray = [...copySuccessArray]
        updatedCopySuccessArray[index] = true
        setCopySuccessArray(updatedCopySuccessArray)

        setTimeout(() => {
          const resetCopySuccessArray = [...copySuccessArray]
          resetCopySuccessArray[index] = false
          setCopySuccessArray(resetCopySuccessArray)
        }, 1000)
      })
      .catch((err) => {
        console.error('Error copying text: ', err)
      })
  }
}

// convertS3UrlToHttps
const convertS3UrlToHttps = (s3Url) => {
  return s3Url.replace(
    /^s3:\/\/([^\/]+)\/(.*)$/,
    'https://$1.s3.amazonaws.com/$2'
  )
}

// Login Scheema validation:

let EMAIL_REGX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
export const LoginSchema = Yup.object({
  email: Yup.string()
    .required('Please enter your email')
    .max(50, 'Email must not exceed 50 characters')
    .matches(EMAIL_REGX, 'Invalid email address'),
  password: Yup.string()
    .required('Please enter your password')
    .max(50, 'Password must not exceed 50 characters'),
})

// Forget password sceema
export const ForgotSchema = Yup.object({
  email: Yup.string()
    .required('Please enter your email')
    .matches(EMAIL_REGX, 'Invalid email address')
    .max(50, 'Email must not exceed 50 characters'),
})
