import React, { useContext, useState } from 'react'
import GlobalContext from '../../context/GlobalContext'
import { useNavigate, NavLink } from 'react-router-dom' // Import useNavigate
// import { quizSideIcon } from "../../";
import { QuizSideIcon } from '../../assets/icons'
import PopupModal from './PopupModel'

const first_box_items = [
  { id: 1, text: 'LAW GAT', href: 'law_gat', icon: <QuizSideIcon /> },
  { id: 2, text: 'SEE LAW', href: 'see_law', icon: <QuizSideIcon /> },
  { id: 3, text: 'LAT', href: 'lat', icon: <QuizSideIcon /> },
]

const Catagories = () => {
  const navigate = useNavigate()

  const [modalMessage, setModalMessage] = useState('')

  const { setSelectedCategory, isLoading, setIsLoading } =
    useContext(GlobalContext) // This function sets the global state
  const [activeItem, setActiveItem] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pendingCategory, setPendingCategory] = useState(null)

  const handleItemClick = (item) => {
    // Set the pending category that will be confirmed
    setIsLoading(false)
    setIsModalOpen(true)
    setSelectedCategory(item)
    setPendingCategory(item)
    setModalMessage(item.text)
  }

  const handleConfirm = (tab) => {
    setIsLoading(true)
    setIsModalOpen(false)
    if (pendingCategory) {
      setActiveItem(pendingCategory.id)
      setSelectedCategory(pendingCategory.href)
      // navigate('MCQs');
    }
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <PopupModal
        isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        message={`Do you really want to attempt ${modalMessage} test?`}
      />
      <div className='history md:fixed xs:static md:block xs:h-[80vh] left-0 md:h-full top-[73px] capitalize  text-white bg-black rounded-r-lg lg:min-w-fit'>
        <h2 className='text-[22px] py-8 pl-8 font-medium bg-black w-full'>
          Choose your Test
        </h2>
        {first_box_items.map((item, index) => (
          <div
            key={item.id}
            className={`flex py-4 mt-[-10px] bg-black pr-24 ${
              activeItem === item.id ? 'bg-[#1D474C]' : ''
            }`}
          >
            <div
              onClick={() => handleItemClick(item)}
              className={`border-l-8 rounded-r-lg mr-14 cursor-pointer h-16 ${
                activeItem === item.id ? 'border-white' : 'border-[#FFFFFF38]'
              }`}
            ></div>
            <div className='mt-2'>
              <QuizSideIcon />
            </div>
            <div className='content capitalize ml-3'>
              {/* <img src={item.icon} alt="icon" /> */}
              <NavLink
                to={item.href}
                className={`no-underline`}
                onClick={(e) => {
                  e.preventDefault() // Prevent default to handle the navigation manually
                  handleItemClick(item)
                }}
              >
                <p className='mb-0 font-medium text-[20px] text-white'>
                  {item.text}
                </p>
              </NavLink>
              <span className='uppercase text-[14px] text-white'>
                attempt 0/3
              </span>
              {index < first_box_items.length - 1 && (
                <div className='quiz-divider mt-10 mb-3'></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Catagories
