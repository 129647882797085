import * as React from 'react'
const AuthGoogleIcon = (props) => (
<svg width="65" height="65" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<rect x="1.24867" y="1.07576" width="74.2497" height="74.2497" rx="37.1249" stroke="#A4A4A4" stroke-opacity="0.25" strokeWidth="0.878197"/>
<g clip-path="url(#clip0_46_49)">
<path d="M33.0464 21.792C29.5366 23.0096 26.5097 25.3206 24.4104 28.3856C22.3111 31.4506 21.25 35.1081 21.383 38.8207C21.5159 42.5333 22.836 46.1055 25.1491 49.0125C27.4623 51.9194 30.6468 54.008 34.2347 54.9714C37.1435 55.722 40.1911 55.7549 43.1155 55.0674C45.7647 54.4724 48.2139 53.1995 50.2234 51.3735C52.3148 49.415 53.8329 46.9235 54.6144 44.1668C55.4638 41.1691 55.615 38.0165 55.0562 34.9512H38.7218V41.7271H48.1816C47.9925 42.8078 47.5874 43.8392 46.9904 44.7597C46.3934 45.6802 45.6169 46.4707 44.7072 47.0841C43.5521 47.8482 42.2499 48.3623 40.8843 48.5935C39.5147 48.8481 38.1099 48.8481 36.7403 48.5935C35.3522 48.3065 34.039 47.7336 32.8845 46.9112C31.0297 45.5982 29.637 43.733 28.9052 41.5816C28.1609 39.39 28.1609 37.0139 28.9052 34.8222C29.4261 33.286 30.2873 31.8872 31.4245 30.7304C32.7258 29.3822 34.3734 28.4185 36.1864 27.9451C37.9994 27.4716 39.9078 27.5067 41.7021 28.0464C43.1039 28.4767 44.3857 29.2285 45.4455 30.2419C46.5121 29.1807 47.5769 28.1168 48.6399 27.0502C49.1888 26.4766 49.787 25.9305 50.3277 25.3432C48.7101 23.8379 46.8113 22.6665 44.7402 21.8963C40.9686 20.5268 36.8418 20.49 33.0464 21.792Z" fill="white"/>
<path d="M33.0464 21.792C36.8415 20.4891 40.9683 20.5249 44.7402 21.8935C46.8117 22.669 48.7096 23.8459 50.325 25.3569C49.7761 25.9442 49.197 26.4931 48.6372 27.0639C47.5724 28.1269 46.5085 29.1862 45.4455 30.2419C44.3857 29.2285 43.1039 28.4767 41.7022 28.0464C39.9084 27.5047 38.0001 27.4677 36.1866 27.9392C34.3731 28.4107 32.7246 29.3726 31.4218 30.7194C30.2846 31.8762 29.4234 33.275 28.9025 34.8112L23.2134 30.4065C25.2497 26.3684 28.7755 23.2795 33.0464 21.792Z" fill="#E33629"/>
<path d="M21.7041 34.7701C22.0098 33.2546 22.5175 31.787 23.2135 30.4065L28.9025 34.8222C28.1583 37.0139 28.1583 39.3899 28.9025 41.5816C27.0071 43.0453 25.1107 44.5162 23.2135 45.9945C21.4712 42.5265 20.9398 38.5752 21.7041 34.7701Z" fill="#F8BD00"/>
<path d="M38.7217 34.9485H55.0562C55.6149 38.0138 55.4637 41.1663 54.6143 44.1641C53.8328 46.9207 52.3147 49.4123 50.2233 51.3708C48.3873 49.9382 46.5431 48.5167 44.7071 47.0841C45.6174 46.4701 46.3943 45.6787 46.9913 44.7572C47.5883 43.8358 47.9931 42.8033 48.1815 41.7216H38.7217C38.7189 39.4657 38.7217 37.2071 38.7217 34.9485Z" fill="#587DBD"/>
<path d="M23.2104 45.9945C25.1077 44.5309 27.0041 43.0599 28.8995 41.5816C29.6328 43.7337 31.0275 45.599 32.8843 46.9111C34.0425 47.7297 35.3585 48.2979 36.7484 48.5797C38.118 48.8344 39.5228 48.8344 40.8924 48.5797C42.258 48.3486 43.5602 47.8344 44.7153 47.0703C46.5513 48.5029 48.3955 49.9244 50.2315 51.357C48.2223 53.184 45.773 54.4578 43.1236 55.0537C40.1992 55.7412 37.1516 55.7082 34.2428 54.9576C31.9422 54.3434 29.7933 53.2605 27.9308 51.7769C25.9594 50.2118 24.3492 48.2393 23.2104 45.9945Z" fill="#319F43"/>
</g>
<defs>
<clipPath id="clip0_46_49">
<rect width="35.1279" height="35.1279" fill="white" transform="translate(20.8096 20.6367)"/>
</clipPath>
</defs>
</svg>)
export default AuthGoogleIcon
