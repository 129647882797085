/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route,Navigate } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Cookies from 'js-cookie'
import Login from '../pages/auth/Login'
import Otp from '../pages/auth/Otp'
import Register from '../pages/auth/Register'
import ResetPassword from '../pages/auth/Reset-Password'
import Home from '../pages/Home'
import GlobalContext from '../context/GlobalContext'
import ForgotPassword from '../pages/auth/ForgotPassword'
import OpenEmail from '../pages/auth/OpenEmail'
import Payment from '../pages/Payment'
import Reporting from '../components/admin/Reporting'
import Dashboard from '../components/admin/Dashboard'
import FeedbackDetails from '../components/admin/FeedbackDetails'
import PromoForm from '../components/admin/PromoForm'
import QuizApp from '../components/quiz/QuizApp'
import LandingPage from '../pages/LandingPage'
import SearchQuery from '../components/SearchQuery'
import PaymentHistory from '../components/PaymentHistory'
import Layout from '../components/Layout'
import RefundPolicy from '../components/RefundPolicy'
import TermsAndConditions from '../components/TermsAndConditions'
import PrivacyPolicy from '../components/PrivacyPolicy'
import AddNewBlogPage from '../components/Blogs/AddNewPostPage'
import PostBlogList from '../components/Blogs/PostBlogList'
import PagePreview from '../components/Blogs/PagePreviewComponent/PagePreview'
import HeroSectionAndBlogsSection from '../components/Blogs/HeroSectionAndBlogsSection'
import SinglePostSection from '../components/Blogs/SinglePostSection/SinglePostSection'
import ThankYouPage from '../pages/ThankYouPage'
import UsersPage from '../pages/UsersPage'
import BlogsFooter from '../components/landingPage/BlogsFooter'
function Routing() {
  const token = Cookies.get('token')
  const { userInfo } = useContext(GlobalContext)
  const [debugMessage, setDebugMessage] = useState('')
 
  useEffect(() => {
    const token = Cookies.get('token')
    if (token && typeof token === 'string' && token.trim() !== '') {
      const expDate = new Date(token.exp * 1000);
      const currentTime = new Date();
      if (currentTime > expDate) {
        Cookies.remove('token');
        Cookies.remove('refresh');
      }
    }
  }, [token]);
  

  return (
    <>
      <Routes>
        {token && (
          <>
            <Route
              path='/'
              element={
                <PrivateRoute>
                  <Home
                    debugMessage={debugMessage}
                    setDebugMessage={setDebugMessage}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path='/:id'
              element={
                <PrivateRoute>
                  <Home
                    debugMessage={debugMessage}
                    setDebugMessage={setDebugMessage}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path='/ChatBot/:id'
              element={
                <PrivateRoute>
                  <Home
                    debugMessage={debugMessage}
                    setDebugMessage={setDebugMessage}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path='/dashboard'
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route
              path='/reporting'
              element={
                <PrivateRoute>
                  <Reporting />
                </PrivateRoute>
              }
            />
            <Route
              path='/feedback'
              element={
                <PrivateRoute>
                  <FeedbackDetails />
                </PrivateRoute>
              }
            />
            <Route
              path='/promo'
              element={
                <PrivateRoute>
                  <PromoForm />
                </PrivateRoute>
              }
            />
            <Route
              path='/mcqs/:category'
              element={
                <PrivateRoute>
                  <QuizApp />
                </PrivateRoute>
              }
            />
            <Route
              path='/payment'
              element={
                <PrivateRoute>
                  <Payment />
                </PrivateRoute>
              }
            />
            <Route
              path='/thank-you'
              element={
                <PrivateRoute>
                  <ThankYouPage />
                </PrivateRoute>
              }
            />

            <Route
              path='/payment-history'
              element={
                <PrivateRoute>
                  <Layout>
                    <PaymentHistory />
                  </Layout>
                </PrivateRoute>
              }
            />

            <Route
              path='*'
              element={
                <PrivateRoute>
                  <Home
                    debugMessage={debugMessage}
                    setDebugMessage={setDebugMessage}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path='/search'
              element={
                <PrivateRoute>
                  <SearchQuery />
                </PrivateRoute>
              }
            />
            <Route
              path='/add-new-post'
              element={
                <PrivateRoute>
                  <Layout>
                    <AddNewBlogPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path='/edit-new-post/:id'
              element={
                <PrivateRoute>
                  <Layout>
                    <AddNewBlogPage />
                  </Layout>
                </PrivateRoute>
              }
            />
            <Route
              path='/preview-page'
              element={
                <PrivateRoute>
                  <Layout>
                    <PagePreview />
                  </Layout>
                </PrivateRoute>
              }
            />

            <Route
              path='/blogs'
              element={
                <PrivateRoute>
                  <Layout>
                    <PostBlogList />
                  </Layout>
                </PrivateRoute>
              }
            />

            <Route
              path='/blogs/:slug'
              element={
                <Layout>
                  <SinglePostSection />
                </Layout>
              }
            />

            <Route
              path='/refund-policy'
              element={
                <PrivateRoute>
                  <RefundPolicy />
                </PrivateRoute>
              }
            />
            <Route
              path='/terms-and-conditions'
              element={
                <PrivateRoute>
                  <TermsAndConditions />
                </PrivateRoute>
              }
            />
            <Route
              path='/privacy-policy'
              element={
                <PrivateRoute>
                  <PrivacyPolicy />
                </PrivateRoute>
              }
            />
          </>
        )}

        {/* //ROLE BASE ROUTES */}
        {token && userInfo?.organization?.org_admin && (
          <Route
            path='/users'
            element={
              <PrivateRoute>
                <UsersPage />
              </PrivateRoute>
            }
          />
        )}

        {!token && (
          <>
            <Route path='/blogs-view'  element={<HeroSectionAndBlogsSection />} />
            <Route path='/blogs-view/:slug' element={<SinglePostSection />} />
            <Route path='*' element={<Navigate to='/login' />} />
            <Route path='/' element={<LandingPage />} />
            <Route path='/refund-policy' element={<RefundPolicy />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditions />}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/' element={<BlogsFooter />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route path='/check-email/:email' element={<OpenEmail />} />
            <Route path='/verify-otp/:email' element={<Otp />} />
            <Route path='/api/user/reset-password/:uid/:token' element={<ResetPassword />} />
          </>
        )}
      </Routes>
    </>
  )
}

export default Routing
