import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const ActsModel = ({ open, onClose, judgement_data }) => {
  if (!open) return null;
  return (
    <div
      onClick={onClose}
      className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-[#767676] bg-opacity-20 backdrop-blur-sm  z-[9999]"
      //   className="editoverlay"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded-[20px] fixed flex flex-col  w-[60%] 2xl:w-[50%] h-auto border-[#fff] border-[3px] z-50 bg-[#F6F6F6]"
      >
        <div
          className="cursor-pointer w-full flex items-end justify-end px-3 pt-3"
          onClick={onClose}
        >
          <AiOutlineClose size={20} className="text-[#13343B]" />
        </div>
        <div className="flex flex-col items-start justify-start w-full px-5 py-3">
          <span className="font-roboto text-[20px] pb-3 2xl:text-[22px] text-[#333] font-[600] leading-[20px]">
            {judgement_data.judgement[0]?.parties?.appellants[0]} VS{" "}
            {judgement_data.judgement[0]?.parties?.respondents[0]}
          </span>
          <span className="py-2 pr-[20px]  font-roboto 2xl:text-[17px]  xl:text-[15px] text-[#505050] font-[400] text-justify font-roboto leading-[28px]">
            In section 298-A of Pakistan Penal Code, the punishment against the
            person who disrespects the wives, family and Companions of Holy
            Prophet(P.B.U.H), imprisonment of with minimum three years period
            alongwith a nominal penalty while this is a bailable crime.
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActsModel;
