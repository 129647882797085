import React from "react";
import { RotatingLines } from "react-loader-spinner";

function SubmitButtonLogin(props) {
  return (
    <button
      type="button"
      onClick={props.handleSubmit}
      className=" rounded-md flex xs:px-[40px] xs:py-[14px] justify-center items-center text-white w-full bg-[#0D9488] text-[17px]"
    >
      {props.loading ? (
        <RotatingLines
          strokeColor="white"
          strokeWidth="2"
          animationDuration="0.75"
          width="25"
          visible={true}
        />
      ) : (
        <>{props.title}</>
      )}
    </button>
  );
}

export default SubmitButtonLogin;
