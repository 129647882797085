import React from 'react'
import { jazzIcon,easypaisaIcon,bankIcon,trusted,trustedWllet } from '../../assets/landingPageImgs/index'
function AffordableLexaComponent({handlePath}) {
  return (
    <div
      className='flex flex-col lg:flex-row justify-center items-center p-8 bg-black text-white'
      id='Price'
    >
      <div className='flex flex-col lg:flex-row justify-between items-start max-w-6xl w-full'>
        <div className='flex-1 md:p-8 xs:p-1'>
          <h1 className='text-5xl mb-6 mt-12'>Affordable Lexa</h1>
          <p className='text-lg mb-6'>
            Lexa's affordability sets it apart, offering all-encompassing legal
            resources, including a vast library of local law literature and
            decades of judgments, all for a modest fee of PKR 2200. This
            positions Lexa as a cost-effective solution for lawyers and law
            students seeking comprehensive legal knowledge and tools.
          </p>
          <ul className='grid grid-cols-2 gap-x-8 gap-y-3 p-0 list-none'>
            <li className='text-base'>✓ Chatbot with Unlimited Queries</li>
            <li className='text-base'>✓ More 2.5 Lac Judgments</li>
            <li className='text-base'>✓ Contract Drafts Templates</li>
            <li className='text-base'>✓ 24/7 Customer Support</li>
          </ul>
        </div>
        <div className='xs:hidden lg:block divider w-[1px] h-[50vh] bg-[#0D9488] mt-24'></div>
        <div className='flex-1 md:p-8 xs:p-0 xs:mt-2 md:mt-0 xs:border-1 xs:rounded-lg md:border-none md:rounded-none'>
          <div className='bg-[#212121] p-8 rounded-lg text-center'>
            <h2 className='md:text-5xl xs:text-xl mb-3'>Limited-Time Price for Students</h2>
            <div className='divider m-auto block w-32 mb-3 border-b-2 border-[#0D9488]'></div>
            <p className='text-sm mb-3'>Used by 500+ Lawyers in Pakistan</p>
            <div className='relative inline-flex justify-center items-baseline mb-5 bg-[#0D9488] rounded-full border-2 border-white py-2 px-4'>
              <span className='absolute top-[-10px] right-[-10px] bg-white text-[#0D9488] text-xs px-2 py-1 rounded-full'>
                50% Discount
              </span>
              <span className='md:text-5xl xs:text-xl mr-1'>999</span>
              <span className='md:text-lg xs:text-sm'>PKR Per Month</span>
            </div>
            <button
              onClick={handlePath}
              className='block m-auto bg-[#0D9488] text-white py-2 px-4 text-lg rounded-3xl mb-3 shadow-lg border-white border-2'
            >
              PAY NOW
            </button>
            <div className='flex flex-col items-center'>
              <img src={trusted} alt='trusted' />
              <div className='mb-3 text-[10px] my-2'>
                <span>
                  <img src={trustedWllet} alt= 'trustedWallet' />
                </span>{' '}
                Trusted by Pakistan Lawyers Associations
              </div>
              <div className='flex space-x-4'>
                <img src={jazzIcon} alt='Jazz Cash' className='w-12' />
                <img src={easypaisaIcon} alt='Easy Paisa' className='w-12' />
                <img src={bankIcon} alt='Bank Transfer' className='w-12' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AffordableLexaComponent
