import React from 'react';
import Marquee from 'react-fast-marquee';

const MarqueeWrapper = ({ items, direction = 'left', speed = 80, renderItem }) => {
  return (
    <Marquee pauseOnHover gradient={false} speed={speed} direction={direction}>
      {items.map((item, index) => renderItem(item, index))}
    </Marquee>
  );
};

export default MarqueeWrapper;
