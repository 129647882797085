import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SubscriberData from "./SubscriberData.json"


const SubscribersDetails = () => {
  return (
    <div className="mt-4 bg-gray-100 overflow-scroll border-2 border-[#f0f0f0]">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="text-2xl font-semibold mb-4">SUBSCRIBERS DETAILS</div>
        <div className="overflow-x-auto overflow-y-scroll">
          <table className="min-w-full text-sm divide-y divide-gray-200 ">
            <thead>
              <tr className="text-left text-gray-500 uppercase bg-gray-50">
                <th className="py-3 px-4 font-medium">#ID</th>
                <th className="py-3 px-4 font-medium">Member Since</th>
                <th className="py-3 px-4 font-medium">User Name</th>
                <th className="py-3 px-4 font-medium">Full Name</th>
                <th className="py-3 px-4 font-medium">Email & Phone</th>
                <th className="py-3 px-4 font-medium">Manage</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {SubscriberData.map((subscriber, index) => (
                <tr key={index} className="bg-white hover:bg-gray-50">
                  <td className="py-3 px-4">{subscriber.id}</td>
                  <td className="py-3 px-4">{subscriber.memberSince}</td>
                  <td className="py-3 px-4">{subscriber.userName}</td>
                  <td className="py-3 px-4">{subscriber.fullName}</td>
                  <td className="py-3 px-4">
                    {subscriber.email}
                    <br />
                    {subscriber.phone}
                  </td>
                  <td className="py-3 px-4">
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-gray-500 cursor-pointer hover:text-gray-700"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubscribersDetails;
