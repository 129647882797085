import * as React from 'react'
const AuthFacebookIcon = (props) => (
<svg width="65" height="65" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg"     {...props}
>
<rect x="1.12074" y="1.07576" width="74.2497" height="74.2497" rx="37.1249" stroke="#A4A4A4" stroke-opacity="0.25" strokeWidth="0.878197"/>
<g clip-path="url(#clip0_46_45)">
<path d="M55.809 38.2005C55.809 28.5003 47.9453 20.6366 38.2451 20.6366C28.5449 20.6366 20.6812 28.5003 20.6812 38.2005C20.6812 46.9671 27.1041 54.2335 35.5007 55.5511V43.2776H31.0411V38.2005H35.5007V34.331C35.5007 29.929 38.123 27.4975 42.135 27.4975C44.0566 27.4975 46.0665 27.8405 46.0665 27.8405V32.1629H43.8518C41.6699 32.1629 40.9895 33.5168 40.9895 34.9059V38.2005H45.8607L45.082 43.2776H40.9895V55.5511C49.3861 54.2335 55.809 46.9673 55.809 38.2005Z" fill="#1877F2"/>
<path d="M45.0819 43.2775L45.8606 38.2004H40.9893V34.9058C40.9893 33.5166 41.6698 32.1628 43.8517 32.1628H46.0664V27.8405C46.0664 27.8405 44.0565 27.4974 42.1347 27.4974C38.1229 27.4974 35.5006 29.9289 35.5006 34.3309V38.2004H31.041V43.2775H35.5006V55.551C36.4085 55.6933 37.326 55.7646 38.245 55.7644C39.1639 55.7646 40.0815 55.6933 40.9893 55.551V43.2775H45.0819Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_46_45">
<rect width="35.1279" height="35.1279" fill="white" transform="translate(20.6816 20.6367)"/>
</clipPath>
</defs>
</svg>)
export default AuthFacebookIcon
