import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SubscriberData from "./SubscriberData.json"
const ReportingDetails = () => {
    return (
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full">
        <table className="min-w-full text-sm divide-y divide-gray-200 ">
            <thead>
              <tr className="text-left text-gray-500 uppercase bg-gray-50">
                <th className="py-3 px-4 font-medium">#ID</th>
                <th className="py-3 px-4 font-medium">Member Since</th>
                <th className="py-3 px-4 font-medium">User Name</th>
                <th className="py-3 px-4 font-medium">Full Name</th>
                <th className="py-3 px-4 font-medium">Email & Phone</th>
                <th className="py-3 px-4 font-medium">Manage</th>
                <th className="py-3 px-4 font-medium">Subscription</th>
                <th className="py-3 px-4 font-medium">Customers</th>
                <th className="py-3 px-4 font-medium">Balance</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {SubscriberData.map((subscriber, index) => (
                <tr key={index} className="bg-white hover:bg-gray-50">
                  <td className="py-3 px-4">{subscriber.id}</td>
                  <td className="py-3 px-4">{subscriber.memberSince}</td>
                  <td className="py-3 px-4">{subscriber.userName}</td>
                  <td className="py-3 px-4">{subscriber.fullName}</td>
                  <td className="py-3 px-4">
                    {subscriber.email}
                    <br />
                    {subscriber.phone}
                  </td>
                  <td className="py-3 px-4">
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-gray-500 cursor-pointer hover:text-gray-700"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </table>
      </div>
    );
  };

export default ReportingDetails
