import * as React from 'react'
const TickIcon = (props) => (
    <svg
    width='15'
    height='12'
    viewBox='0 0 15 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.5802 1.88467L7.70097 11.2202C7.5369 11.4379 7.29226 11.5805 7.02201 11.616C6.75175 11.6515 6.47856 11.577 6.26381 11.4091L1.35133 7.48155C0.917836 7.13464 0.847643 6.50201 1.19455 6.06851C1.54146 5.63502 2.1741 5.56482 2.60759 5.91173L6.704 9.18906L12.9622 0.695743C13.1674 0.387799 13.5252 0.217543 13.8936 0.252573C14.262 0.287602 14.5814 0.522249 14.7249 0.863351C14.8684 1.20445 14.8128 1.59682 14.5802 1.88467Z'
      fill='#252B38'
    />
  </svg>
)
export default TickIcon
