/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React from "react";

import { bgHero } from '../../assets'
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { LexaLogoIcon} from '../../assets/icons'
import { Api } from "../../api";

function OpenEmail() {
  const Navigate = useNavigate();
  const { email } = useParams();
  // const parts = email.split("@");
  // const handleOpenGmailButtonClick = () => {
  //   window.open(`https://www.${parts[1]}`, "_blank");
  // };

  const resendEmail = async () => {
    await Api.resetPassword(email)

      .then((response) => {
        Swal.fire({
          icon: "success",
          text: response.data.msg,
          customClass: {
            confirmButton: "custom-confirm-button",
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          customClass: {
            confirmButton: "custom-confirm-button",
          },
        });
      });
  };

  return (
    <>
      <div
        className="max-w-screen max-h-screen xs:min-h-screen bg-no-repeat bg-cover flex justify-center items-center"
        style={{ backgroundImage: `url(${bgHero})` }}
      >
        <div
          className=" bg-black xs:w-[95%] sm:w-[75%] md:w-[60%] lg:w-[45%] xl:w-[35%] 2xl:w-[40%] 3xl:w-[40%] h-[95vh] flex flex-col md:py-[10px] 2xl:py-[30px] justify-between items-center
       
          rounded-[54px] border-r-2 border-l-2 border-[#ACACAC] shadow-[0_0px_4px_0px_rgba(0,0,0,0.25)]"
        >
          <div className="w-full flex flex-col items-center gap-[35px]">
            <div className="  md:w-[100%] mt-[15px] gap-3 flex flex-col items-center justify-center md:h-[75px] 2xl:w-auto 2xl:h-auto 3xl:w-[500px] 4xl:w-[800px] 4xl:mt-10">
            <LexaLogoIcon/>

            </div>
          </div>

          <div className="mt-2 w-[70%] h-[50%]  flex flex-col items-center justify-start">
            <div className="flex w-full flex-col items-center gap-3 justify-center ">
              <span className=" text-capitalize font-[Poppins] text-white text-[25px] font-[500] 3xl:text-2xl 4xl:text-[35px]">
                Check your email
              </span>
              <span className=" text-center font-roboto text-[16px] md:text-[15px] 3xl:text-2xl 4xl:text-[35px] text-white">
                We sent a password reset link to{" "}
                <span className="text-[#20808d]"> {email} </span>
              </span>
              {/* <button
                type="button"
                onClick={() => handleOpenGmailButtonClick()}
                className="flex justify-center items-center bg-[#20808d]
                 md:w-[150px] 2xl:w-[180px] h-[40px] 
                 2xl:h-[50px] 3xl:w-[190px] 3xl:h-[65px] 
                 4xl:w-[280px] 4xl:h-[120px] 3xl:text-2xl 
                 4xl:text-[35px] rounded-[150px] text-white font-medium
                  text-[16px] 2xl:text-[17px]"
              >
                Open Email
              </button> */}
              <span className=" font-roboto text-[15px] 3xl:text-2xl 4xl:text-[35px] text-white">
                Didn’t receive the email?{" "}
                <span
                  onClick={() => resendEmail()}
                  className="text-[#1F88FE] font-[700] cursor-pointer"
                >
                  Click to resend
                </span>
              </span>
            </div>
          </div>
          <span className="font-roboto text-[15px] 3xl:text-2xl 4xl:text-[35px] text-[#5A5A5A]">
            Back to,{" "}
            <span
              className="text-[#1F88FE] font-[700] cursor-pointer"
              onClick={() => Navigate("/login")}
            >
              {" "}
              Sign in
            </span>
          </span>
        </div>
      </div>
    </>
  );
}

export default OpenEmail;
