// Library Imports
import React, { useContext } from 'react'
import GlobalContext from '../../../context/GlobalContext'
import { Box,Tooltip} from '@mui/material'

// Local Imports
import Cookies from 'js-cookie'
import { DiamondIcon,TickIcon,LeftArrowIcon} from '../../../assets/icons'

const PaymentCard = ({ setSelectedPlanId, ...plan }) => {
  const { isOnTrial } = useContext(GlobalContext)
  const { id, name, amount, interval, description } = plan
  const descriptionLines = description?.split('\n')
  const trail = Cookies.get('trail')
  const subscription = Cookies.get('subscription')
  const isSubscribed = parseInt(subscription) === id; // Check if subscription matches the current plan id

  const cardStyles = {
    pointerEvents: trail && id === 7 ? 'none' : 'auto', // Disable pointer events when on trial and id is 7
    filter: trail && id === 7 ? 'blur(0.5px)' : 'none', // Apply blur effect under the same conditions
    opacity: trail && id === 7 ? 0.9 : 1, // Reduce opacity to indicate non-interactivity
  }

  return (
    <React.Fragment>
      <Box width='100%' minWidth='20%' paddingInline='20px'>
        {/* Top Badge */}
        {id === 2 && (
          <div className='bg-[#13343B] py-[10px] uppercase text-center text-white text-[13px] rounded-tl-[10px] rounded-tr-[10px]'>
            Most popular
          </div>
        )}

        {/* Card */}
        <Box
          component='div'
          style={cardStyles}
          bgcolor={trail && id === 7 || isSubscribed ? '#f0f0f0' : '#ffffff'} // Example of conditional background
          className={`flex flex-col border border-[#CEE9FF] h-[max-content] rounded-bl-[10px] rounded-br-[10px] p-[20px] gap-3 ${
            id === 2
              ? 'rounded-tl-[0px] rounded-tr-[0px]'
              : 'rounded-tl-[10px] rounded-tr-[10px]'
          }`}
        >
          <div className='bg-[#FEC67B2B] w-[55px] h-[55px] flex items-center justify-center rounded-full'>
            <DiamondIcon/>
          </div>
          <h1 className='font-[700] text-[1.4rem]'>{name}</h1>
          {id === 7 ? (
            <h1 className='font-[700] text-[1.5rem]'>Free</h1>
          ) : (
            <h1 className='font-[700] text-[1.5rem]'>
              RS {amount}
              <span className='text-[10px] font-normal text-[#A6A5A8] ml-3 uppercase'>
                / {interval}
              </span>
            </h1>
          )}
           <Tooltip title={isSubscribed ? 'Currently Active Plan' : ''}>

          <button
            className={`w-full bg-[#13343B] text-white rounded-md  text-[14px] font-[700] py-3 ${
              isOnTrial && id === 7 || isSubscribed ? 'disable' : ''
            }`}
            onClick={() => !isSubscribed &&  setSelectedPlanId({ planId: id })}
            disabled={isSubscribed}
          >
            {trail && id === 7 || isSubscribed ? 'Subscribed' : 'Subscribe'}
          </button>
          </Tooltip>


          <h5 className='text-[#13343B] text-[1.2rem] font-[700]'>
            Plan Include:
          </h5>
          <div>
            <div>
              {descriptionLines?.map((line, index) => (
                <p
                  key={index}
                  className='text-[#13343B] text-[1rem] font-[600] flex gap-4 items-center'
                >
                  <TickIcon/>
                  <span className='text-[#5A5A5A] font-normal text-[13px]'>
                    {line}
                  </span>
                </p>
              ))}
            </div>
          </div>
        </Box>
      </Box>
    </React.Fragment>
  )
}
export const PaymentCardMobile = ({ setSelectedPlanId, ...plan }) => {
  const { id, name, amount, interval, description } = plan
  const descriptionLines = description?.split('\n')

  return (
    <React.Fragment>
      <Box width='100%' minWidth='20%' paddingInline='20px'>
        {/* Card */}
        <Box
          component='div'
          className={`flex flex-col  h-[max-content] rounded-bl-[10px] rounded-br-[10px] p-[20px] gap-3 ${
            id === 2
              ? 'rounded-tl-[0px] rounded-tr-[0px]'
              : 'rounded-tl-[10px] rounded-tr-[10px]'
          }`}
        >
          <div className='bg-[#FEC67B2B] w-[55px] h-[55px] flex items-center justify-center rounded-full'>
            <DiamondIcon/>
          </div>
          <h1 className='font-[700] text-[1.4rem]'>{name}</h1>
          <h1 className='font-[700] text-[1.5rem]'>
            RS {amount}
            <span className='text-[12px] font-normal text-[#A6A5A8] ml-3 uppercase'>
              / {interval}
            </span>
          </h1>
          <button
            className='w-full bg-[#13343B] text-white rounded-md  text-[14px] font-[700] py-3'
            onClick={() =>
              setSelectedPlanId({ planId: id, type: 'mobile', planName: name })
            }
          >
            Subscribe
          </button>

          <h5 className='text-[#13343B] text-[1.2rem] font-[700]'>
            Plan Include:
          </h5>
          <div>
            {descriptionLines?.map((line, index) => (
              <p
                key={index}
                className='text-[#13343B] text-[1rem] font-[600] flex gap-4 items-center'
              >
                <TickIcon/>
                <span className='text-[#5A5A5A] font-normal text-[13px]'>
                  {line}
                </span>
              </p>
            ))}
          </div>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export const PaymentCardMobileStart = ({ setSelectedPlanId, ...plan }) => {
  const { id, name, amount, interval } = plan
  const subscription = Cookies.get('subscription')
  const trail = Cookies.get('trail')
  const isSubscribed = parseInt(subscription) === id; // Check if subscription matches the current plan id

  const cardStyles = {
    pointerEvents: trail && id === 7 ? 'none' : 'auto', // Disable pointer events when on trial and id is 7
    filter: trail && id === 7 ? 'blur(0.5px)' : 'none', // Apply blur effect under the same conditions
    opacity: trail && id === 7 ? 0.9 : 1,
  }
  return (
    <Box
      component='div'
      style={cardStyles}
      bgcolor={trail && id === 7 || isSubscribed ? '#f0f0f0' : '#ffffff'} //
      width='100%'
      display='flex'
      alignItems='center'
      gap='20px'
      borderBottom='1px solid #CEE9FF'
      padding='30px 30px'
      position='relative'
    >
      {id === 2 && (
        <div className='bg-[#13343B] rotate-180  absolute left-0 writing-mode  uppercase text-center text-white text-[13px] h-[100%]'>
          Most popular
        </div>
      )}
      <Box
        component='div'
        display='flex'
        gap='10px'
        flexDirection='column'
        flex='1'
        // padding='0px 20px'
      >
        <div className='flex gap-[20px] justify-start items-center'>
          <div className='bg-[#FEC67B2B] w-[50px] h-[50px] flex items-center justify-center rounded-full'>
            <DiamondIcon/>
          </div>
          <div>
            <h1 className='font-[500] text-[1.2rem]'>{name}</h1>
            <h1 className='font-[700] text-[1.5rem]'>
              RS {amount}
              <span className='text-[10px] font-normal text-[#A6A5A8] ml-3 uppercase'>
                / {interval}
              </span>
            </h1>
          </div>
        </div>

        <button
          className='w-full bg-[#13343B] text-white rounded-md  text-[14px] font-[700] py-2'
          onClick={() => !isSubscribed &&  setSelectedPlanId({ planId: id, type: 'mobile' })}
            disabled={isSubscribed}
          // onClick={() => setSelectedPlanId({ planId: id, type: 'mobile' })}
        >
            {trail && id === 7 || isSubscribed ? 'Subscribed' : 'Subscribe'}
          {/* {trail && id === 7 ? 'Subscribed ' : 'Subscribe'} */}
        </button>
      </Box>
      <Box
        component='div'
        className='cursor-pointer'
        onClick={() => setSelectedPlanId({ planId: id, type: 'mobile' })}
      >
        <LeftArrowIcon />
      </Box>
    </Box>
  )
}

export default PaymentCard
