import React from 'react';

const CardData = ({ image, title, subtitle, description, rating }) => {
  return (
    <div className="mx-8 flex flex-col items-center text-center cursor-pointer">
      {image && <img className="mb-2 w-20 h-20" src={image} alt={title} />}
      <h3 className="text-lg font-medium text-white mb-1">{title}</h3>
      {subtitle && <p className="text-sm text-gray-400">{subtitle}</p>}
      {rating && <span className="text-yellow-500">{'⭐'.repeat(rating)}</span>}
      {description && <p className="text-sm text-gray-300">{description}</p>}
    </div>
  );
};

export default CardData;
