import React from 'react'
import { profileImg } from '../../assets';
function FeedbackDetails({ isOnline }) {
    const statusColorClass = isOnline ? 'bg-green-500' : 'bg-gray-500';
    return (
        <div>
            <div className="feedback-details">
                <div className="feedback-profile">
                    <div className="relative inline-block">
                        <img
                            src={profileImg}
                            alt="User profile"
                            className="w-12 h-12 rounded-full object-cover"
                        />
                        <span className={`absolute top-0 left-0 w-3 h-3 rounded-full ${statusColorClass} border-2 border-white`}></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackDetails
