import * as React from 'react'
const EasyPaisaIconMobile = (props) => (
  <svg
    width={70}
    height={27}
    viewBox='0 0 79 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M79 12.7341C69.8579 22.7518 63.1708 15.1811 55.9565 16.9261C60.769 12.934 68.4802 10.3397 79 12.7341ZM62.1265 8.862C43.3366 6.34885 44.3049 19.3543 35.7509 23.6236C44.4443 24.2257 54.6016 20.3336 62.1265 8.86325V8.862ZM35.3187 23.6911C33.8117 24.8053 31.9714 25.6784 29.7597 26.3204C27.7417 26.8923 25.6367 27.1059 23.5428 26.9512C21.0232 26.7862 18.6028 25.92 16.563 24.453C14.4746 23.0135 12.8586 21.0038 11.914 18.671C7.74157 18.0952 2.90752 15.5408 0 12.964C3.60715 14.2805 7.85818 15.0587 11.009 14.7839C10.5616 12.6092 11.2714 8.40234 13.3665 5.51571C15.5896 2.44797 18.5491 0.28831 22.3654 0.0359959C28.3908 -0.368706 31.1551 2.72902 31.9866 4.81248C34.8954 12.0709 27.8407 19.1544 19.4059 19.0945C20.2272 20.5084 21.7456 22.0585 24.6113 23.1452C27.2932 24.1545 31.5163 24.3119 35.3187 23.6911ZM17.5744 14.6477C17.5744 14.6477 23.7849 14.2118 25.7773 9.93496C27.0232 7.25568 25.2412 5.49198 23.1334 5.71432C20.4819 5.98912 15.966 8.40359 17.5744 14.6477Z'
      fill='#99C515'
    />
  </svg>
)
export default EasyPaisaIconMobile
