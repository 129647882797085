
import React, { useContext } from "react";
import "../styles/general.css";
import { sendIcon,sendIconActive } from '../assets'
import { ThreeDots } from "react-loader-spinner";
import GlobalContext from "../context/GlobalContext";
import Cookies from "js-cookie";
import { SocketContext } from "../context/SocketContext"; // Update the path accordingly

function JudgementInput(props) {
  const {
    setJudgementChatData,
    setJudgementChatLoading,
    setJudgementErrorMessage,
    setJudgementInputQuery,
    judgementInputQuery,
    setJudgementSendBtnLoading,
    judgementSendBtnLoading,
    selectedTab,
    setQuerySubmission,
    clearJudgementChatData,
  } = useContext(GlobalContext);
  const { sendJudgmentSocketCommand } = useContext(SocketContext);

  const handleChange = (e) => {
    setJudgementInputQuery(e.target.value);
  };
  const handleApiSubmit = (e) => {
    e.preventDefault();
    clearJudgementChatData();  
    setJudgementErrorMessage("");
    setJudgementSendBtnLoading(true);
    setJudgementChatLoading(true);
    const trimmedQuery = judgementInputQuery.trim();
    if (trimmedQuery !== "") {
      const messageWithLineBreaks = trimmedQuery.replace(/\n/g, "\n");
      setJudgementChatData([{ role: "user", content: messageWithLineBreaks }]);
      props.setStoredQuery(messageWithLineBreaks);
      Cookies.set("query", messageWithLineBreaks);
      setJudgementInputQuery("");
      setQuerySubmission(true)
      sendJudgmentSocketCommand({ query: judgementInputQuery });
    } else {
      setJudgementSendBtnLoading(false);
      setJudgementChatLoading(false);
    }
  };

  const autoResize = (e) => {
    e.target.style.height = "24px";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  
  return (
    <>
      <div
        className="pb-[15px] flex items-center justify-end gap-2"
        style={{
          width: selectedTab === "Judgement" ? "95%" : "100%",
        }}
      >
      </div>

      <form
        id="input-container"
        className="dark:bg-white"
        style={{
          width: selectedTab === "Judgement" ? "95%" : "100%",
          marginTop:'20px',
        }}
      >
        <textarea
          type="text"
          placeholder="Hello, how can I help you..."
          onInput={autoResize}
          value={judgementInputQuery}
          onChange={(e) => handleChange(e)}
          onKeyUp={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.stopPropagation();
              if (judgementInputQuery && !judgementSendBtnLoading) {
                handleApiSubmit(e);
              }
            }
          }}
          style={{
            height: `${!judgementInputQuery && "50px"}`,
           
            outline: 'none',
            borderColor: 'transparent',
            boxShadow: 'none'
          }}
        />

        {judgementSendBtnLoading ? (
          <ThreeDots
            height="30"
            width="30"
            radius="9"
            color="gray"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (
          <button type="submit">
            <>
              {judgementInputQuery ? (
                <img
                  src={sendIconActive}
                  alt='sending icon'
                  onClick={
                    judgementInputQuery && !judgementSendBtnLoading
                      ? handleApiSubmit
                      : (e) => e.preventDefault()
                  }
                />
              ) : (
                <>
                  <img
                    src={sendIcon}
                    alt='sending icon'
                    onClick={(e) => e.preventDefault()}
                  />
                </>
              )}
            </>
          </button>
        )}
      </form>
    </>
  );
}

export default JudgementInput;
