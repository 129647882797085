import React from 'react'
import { featureJudgments ,featureChatBot, featureQuery, featureDraft} from '../../assets/landingPageImgs'
import { HeaderSections } from '../shared/landingPageComponent'
const featuresData = [
  {
    icon: featureChatBot,
    title: 'Chat Bot',
    description:
      "Lexa's chatbot feature is a cutting-edge AI-driven tool designed to revolutionize legal consultation in Pakistan. By offering instant access to legal information and resources, also simplifies complex legal queries with quick retrieval of statutes and case laws.",
  },
  {
    icon: featureJudgments,
    title: 'Judgements',
    description:
      'Lexa grants users access to a comprehensive database of legal precedents, offering detailed analyses of court judgments. This feature is invaluable for legal professionals and law students alike, providing critical insights that inform and guide decision-making.',
  },
  {
    icon: featureQuery,
    title: 'General Queries',
    description:
      "Lexa's AI-driven interface excels in addressing general legal queries with speed, clarity, and authority. Designed to simplify the complexities of legal issues, it provides users—whether legal professionals or students—with quick access to precise answers.",
  },
  {
    icon: featureDraft,
    title: 'Mcq’s & Draft',
    description:
      "Lexa prepares aspiring lawyers for the GAT (Lawyer Test for Entry) by providing a diverse range of practice questions. Lexa's drafting feature offers templates and guided assistance, facilitating the creation of legal documents such as contracts and briefs.",
  },
]

const Feature = ({ icon, title, description }) => (
  <div className='bg-[#212121] text-white md:p-6 xs:p-3 rounded-md shadow-md'>
    <div className='mb-4'>
      <img src={icon} alt={title} className='md:w-auto xs:w-20' />
    </div>
    <h3 className='md:text-5xl xs:text-[27px] font-semibold mb-2'>{title}</h3>
    <p className='md:text-base xs:text-[16px]'>{description}</p>
  </div>
)

const Features = () => (
  <div className='bg-black py-12 px-12' id='Features'>
    <div className='content text-center'>
      <HeaderSections title='Features' descriptionDetails = ' Lexa is built on 5 major features'/>
    </div>
    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center'>
      {featuresData.map((feature, index) => (
        <Feature key={index} {...feature} />
      ))}
    </div>
  </div>
)

export default Features
