import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
// Local Import
import GlobalContext from "../../../context/GlobalContext";
import {InfoIcon,EasyPaisaIconMobile,LeftArrowIcon} from '../../../assets/icons';

const EasyPaisaMethod = ({ onClickPayNow, loading, error }) => {
  // NAVIGATION
  const { themeToggle, } = useContext(GlobalContext);
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Box
        component='div'
        padding='40px'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        flexWrap='wrap'
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          gap='30px'
        >
          <div className='flex flex-col gap-2 '>
            <div className='flex items-start gap-2'>
              <InfoIcon />
              <p className={`text-[14px] leading-4 ${themeToggle ? "text-white" : "text-[#333333]"} font-[400]`}>
                Experience easy payments – save your Easypaisa account as
                default method to pay! <br />
                <br />
                Please ensure your Easypaisa account is Active and has
                sufficient balance.
              </p>
            </div>
            <div className='ml-5'>
              <p className={`text-[14px] leading-4 ${themeToggle ? "text-white" : "text-[#333333]"} font-[400]`}>
                To confirm your payment after providing OTP:
              </p>
              <p className={`text-[16px] font-600 leading-4 ${themeToggle ? "text-white" : "text-[#333333]"} font-[400]`}>
                OR
              </p>
              <ul className={`list-disc -ml-5 ${themeToggle ? "text-white" : "text-[black]"}`}>
                <li>
                  <p className={`text-[14px] font-400 mb-0 leading-4 ${themeToggle ? "text-white" : "text-[#333333]"} font-[400]`}>
                    Approve Payment in your Easypaisa App (Telenor and Other
                    Networks)
                  </p>
                </li>
                <li>
                  <p className={`text-[14px] font-400 mb-0 leading-4 ${themeToggle ? "text-white" : "text-[#333333]"} font-[400]`}>
                    Login to Easypaisa App and tap on payment notification to
                    approve
                  </p>
                </li>
                <li>
                  <p className={`text-[14px] font-400 mb-0 leading-4 ${themeToggle ? "text-white" : "text-[#333333]"} font-[400]`}>
                    If you miss the notification, go to My Approvals in side
                    menu to confirm
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </Box>
        <Box display='flex' alignItems='start' flexDirection='column' mt='50px'>
          <div className='flex items-center gap-[12px]'>
            <button
              className='bg-[#20808D] hover:bg-[#20808D] px-6 py-2 text-white rounded-full'
              onClick={() => onClickPayNow('easypaisa')}
            >
              {loading ? (
                <CircularProgress
                  color='inherit'
                  size={18}
                  sx={{ marginTop: '3px' }}
                />
              ) : (
                'Pay Now'
              )}
            </button>
            <button
              className='hover:bg-gray-200 px-6 py-2 rounded-full'
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
          <div className='w-full mt-[auto] pt-2 '>
            <p className={`text-[#FF0000] text-[14px]`}>
              {error?.type === 'wrong' ? error?.message : ''}
            </p>
          </div>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default EasyPaisaMethod

export const EasyPaisaMethodMobile = ({ onClickPayNow, loading, error }) => {
  // NAVIGATION
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Box
        component='div'
        padding='0px 40px 40px 40px '
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        minHeight='50vh'
        flexWrap='wrap'
      >
        {/* Back Icon */}
        <div
          className='flex items-center pt-3 cursor-pointer hover:text-[#676565]'
          onClick={() => navigate(-1)}
        >
          <div>
            <LeftArrowIcon className={'rotate-180'} />
          </div>
          <div className='flex items-center gap-1'>
            <p className={`font-[600] mb-0`}>Payment Details</p>
          </div>
        </div>

        {/* Payment Logo  */}
        <div className='flex items-center gap-4 py-3 '>
          <EasyPaisaIconMobile />
          <p
            className={` font-[600] text-[20px] font-['ui-sans-serif'] translate-y-2`}
          >
            Easy Paisa
          </p>
        </div>

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          gap='30px'
        >
          <div className='flex flex-col gap-2 '>
            <div className='flex items-start gap-2'>
              <p className='text-[14px] leading-4 text-[#333333] font-[400]'>
                Experience easy payments – save your Easypaisa account as
                default method to pay! <br />
                <br />
                Please ensure your Easypaisa account is Active and has
                sufficient balance.
              </p>
            </div>
            <div className=''>
              <p className='text-[14px] leading-4 text-[#333333] font-[400]'>
                To confirm your payment after providing OTP:
              </p>
              <p className='text-[16px] leading-4 text-[#333333] font-[600]'>
                OR
              </p>
              <ul className='list-disc -ml-5 '>
                <li>
                  <p className='text-[14px]  text-[#333333] font-[400] mb-0'>
                    Approve Payment in your Easypaisa App (Telenor and Other
                    Networks)
                  </p>
                </li>
                <li>
                  <p className='text-[14px]  text-[#333333] font-[400] mb-0'>
                    Login to Easypaisa App and tap on payment notification to
                    approve
                  </p>
                </li>
                <li>
                  <p className='text-[14px] text-[#333333] font-[400] mb-0'>
                    If you miss the notification, go to My Approvals in side
                    menu to confirm
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </Box>
        <Box display='flex' alignItems='center' gap='10px' mt='40px'>
          <button
            className='bg-[#20808D] hover:bg-[#20808D] px-6 py-2 text-white rounded-full'
            onClick={() => onClickPayNow('easypaisa')}
          >
            {loading ? (
              <CircularProgress
                color='inherit'
                size={18}
                sx={{ marginTop: '3px' }}
              />
            ) : (
              'Pay Now'
            )}
          </button>
          <button
            className='hover:bg-gray-200 px-6 py-2 rounded-full'
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </Box>
        <div className='w-full mt-3'>
          <p className={`text-[#FF0000] text-[14px]`}>
            {error?.type === 'wrong' ? error?.message : ''}
          </p>
        </div>
      </Box>
    </React.Fragment>
  )
}
