// Local Import
import AssetsImages from '../../../../assets/blogAssets/assets';

//************************* Blogs Table Data *******************************//
export const blogPageColumnData = [
  { name: 'blogTitle', title: 'Title' },
  { name: 'author', title: 'Author' },
  { name: 'tags', title: 'Tags' },
  { name: 'created', title: 'Created' },
  { name: 'status', title: 'Status' },
  { name: 'action', title: 'Action' },
];
export const blogPageColumnExtensionsData = [
  { columnName: 'blogTitle', width: 350 },
  { columnName: 'created', width: 200 },
  { columnName: 'tags', width: 200 },
  { columnName: 'action', width: 100 },
];

export const blogPageRowData = [
  {
    id: 1,
    blogTitle: 'How to use Luxelocker',
    author: 'LuxeLesee',
    tags: ['Luxelocker', 'Camera'],
    created: 'June 10, 2022, 11:17 a.m.',
    status: true,
  },
  {
    id: 2,
    blogTitle: 'How to use Luxelocker',
    author: 'LuxeLesee',
    tags: ['Luxelocker', 'Camera'],
    created: 'June 10, 2022, 11:17 a.m.',
    status: false,
  },
  {
    id: 3,
    blogTitle: 'How to use Luxelocker',
    author: 'LuxeLesee',
    tags: ['Luxelocker', 'Camera'],
    created: 'June 10, 2022, 11:17 a.m.',
    status: true,
  },
  {
    id: 4,
    blogTitle: 'How to use Luxelocker',
    author: 'LuxeLesee',
    tags: ['Luxelocker', 'Camera'],
    created: 'June 10, 2022, 11:17 a.m.',
    status: false,
  },
];
//************************* Blogs Table Extensions *******************************//


