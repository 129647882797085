import React, { useState } from 'react'
import { bgHero } from '../../assets'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import { useParams } from 'react-router'
import Input from '../../components/shared/Input'
import SubmitButton from '../../components/shared/SubmitButton'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import FormErrors from '../../components/shared/FormErrors'
import { LexaLogoIcon} from '../../assets/icons'

import { Api } from '../../api'

const LoginSchema = Yup.object({
  password: Yup.string()
    .required('Please enter your password')
    .min(6, 'Password length must be at least 6 characters long!')
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      'Password must contain at least one alphabetic character and one special character'
    )
    .max(50, 'Password must not exceed 50 characters'),
  confirm_password: Yup.string()
    .required('Please enter your confirm password')
    .oneOf([Yup.ref('password'), null], 'Password must match'),
})

const initialValues = {
  password: '',
  confirm_password: '',
}

function ResetPassword() {
  const { uid, token } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: async (values, action) => {
        setLoading(true)
        await Api.resetEmailPassword(values, token, uid)
          .then((response) => {
            action.resetForm()
            setLoading(false)
            Swal.fire({
              icon: 'success',
              text: 'Password reset successfully',
              customClass: {
                confirmButton: 'custom-confirm-button',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                navigate('/login')
              }
            })
          })
          .catch((error) => {
            setLoading(false)

            Swal.fire({
              icon: 'error',
              text: error.response.data.message,
              customClass: {
                confirmButton: 'custom-confirm-button',
              },
            })
          })
      },
    })

  return (
    <>
      <div
        className='max-w-screen max-h-screen xs:min-h-screen bg-no-repeat bg-cover flex justify-center items-center'
        style={{ backgroundImage: `url(${bgHero})` }}
      >
       <div
          className=" login_box bg-transparent xs:w-[95%] sm:w-[75%] md:w-[60%] lg:w-[45%] xl:w-[35%] 2xl:w-[40%] 3xl:w-[40%] h-[95vh] flex flex-col md:py-[10px] 2xl:py-[20px] justify-between items-center
       
          rounded-[54px] border-r-2 border-l-2 border-[#0c1c1e] shadow-[0_0px_4px_0px_rgba(0,0,0,0.25)] overflow-auto"
        >
          <div className='w-full flex flex-col items-center h-full'>
            <div className='  md:w-[100%] mt-[15px] gap-3 flex flex-col items-center justify-center md:h-[75px] 2xl:w-auto 2xl:h-auto 3xl:w-[500px] 4xl:w-[800px] 4xl:mt-10'>
             <bgHero/>
            </div>
            <form
              onSubmit={handleSubmit}
              className='xs:w-[90%] sm:w-[80%] md:w-[70%] lg:w-[70%] xl:w-[65%] 2xl:w-[60%] h-[65%] flex flex-col  justify-center gap-[15px]'
            >
              <h1 className='text-center mt-[3vh] mb-[2vh] md:text-[4vh] 2xl:text-[3vh] text-white'>
                Change Password
              </h1>
              <div className='md:mt-2 2xl:mt-3 3xl:mb-5'>
                <div className='w-full relative'>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Enter Your New Password'
                    name='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.password}
                    text='New Password'
                  />
                  <div className='absolute md:top-[8px] 2xl:top-4 right-3'>
                    {showPassword ? (
                      <AiFillEyeInvisible
                        onClick={() => setShowPassword(false)}
                        className='cursor-pointer text-[15px] text-gray-400'
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setShowPassword(true)}
                        className='cursor-pointer text-[15px] text-gray-400'
                      />
                    )}
                  </div>
                </div>
                {errors.password && touched.password ? (
                  <FormErrors error={errors.password} />
                ) : null}
              </div>
              <div className='md:mt-2 2xl:mt-3 3xl:mb-5'>
                <div className='w-full relative'>
                  <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder='Confirm New Password'
                    name='confirm_password'
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.confirm_password}
                    text='Re-enter Password'
                  />
                  <div className='absolute md:top-[8px] 2xl:top-4 right-3'>
                    {showConfirmPassword ? (
                      <AiFillEyeInvisible
                        onClick={() => setShowConfirmPassword(false)}
                        className='cursor-pointer text-[15px] text-gray-400'
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setShowConfirmPassword(true)}
                        className='cursor-pointer text-[15px] text-gray-400'
                      />
                    )}
                  </div>
                </div>
                {errors.confirm_password && touched.confirm_password ? (
                  <FormErrors error={errors.confirm_password} />
                ) : null}
              </div>

              <div className='flex justify-end md:mt-4 4xl:mt-[50px]'>
                <SubmitButton
                  title='Change Password'
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              </div>
            </form>
          </div>
          <div className='md:text-[2vh] 2xl:text-[15px] 3xl:text-[2vh] 4xl:mb-5 text-white'>
            Already have an account?{' '}
            <Link
              to='/'
              className='font-semibold text-decoration-none text-[#1f88fe]'
            >
              Log in
            </Link>{' '}
            to get started!
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
