// InfoBox.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown,faChevronRight} from '@fortawesome/free-solid-svg-icons';

const InfoBox = ({ title, amount, percentage, color, iconImg }) => {
    const icon = percentage >= 0 ? faCaretUp : faCaretDown;
    const percentageColor = percentage >= 0 ? 'text-green-500' : 'text-red-500';

    return (
        <div className={`flex flex-col justify-between bg-white rounded-lg border border-gray-200 shadow-md ${color}`}>
            <div className="flex justify-between items-center p-8">
                <div className='wrapper flex justify-between'>
                    <div className="heading-wrapper">
                        <h2 className="text-lg font-light text-gray-500">{title}</h2>
                        <p className="text-2xl font-bold">{amount}</p>
                    </div>
                    <div className={` ml-6 pl-3 items-center ${percentageColor}`}>
                        <img src={iconImg} alt='percentage icon'/>
                        <FontAwesomeIcon icon={icon} className="mr-1 pt-2" />
                        {percentage}%
                    </div>
                </div>
            </div>
            <button className={`flex mt-4 pl-3 text-black text-xs font-semibold pt-2 pb-2 justify-between ${color}`} > More Detail 
            <FontAwesomeIcon icon={faChevronRight} className="mr-3 mt-[2px]" />
            </button>
        </div>
    );
};

export default InfoBox;
