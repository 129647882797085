/* eslint-disable no-useless-escape */
import React, { useState, useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import ErrorMessages from '../../staticData/errors.json'
import Input from '../../components/shared/Input'
import SubmitButton from '../../components/shared/SubmitButton'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import FormErrors from '../../components/shared/FormErrors'
import { Api } from '../../api'
import Cookies from 'js-cookie'
import Turnstile from 'react-turnstile'
import GlobalContext from '../../context/GlobalContext'
import GoogleAuth from './GoogleAuth'
import FacebookAuth from './FacebookAuth'
import '../../styles/general.css'
import { LexaLogoIcon,LoginMessageIcon,AuthGoogleIcon,AuthFacebookIcon,RegisterSpeakerIcon } from '../../assets/icons'

let EMAIL_REGX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

const LoginSchema = Yup.object({
  firstname: Yup.string()
    .matches(
      /^[a-zA-Z\s]*[a-zA-Z]+[a-zA-Z\s]*$/,
      'First name must contain only English letters'
    )
    .required('Please enter your first name')
    .max(50, 'First name must not exceed 50 characters'),
  lastname: Yup.string()
    .matches(
      /^[a-zA-Z\s]*[a-zA-Z]+[a-zA-Z\s]*$/,
      'Last name must contain only English letters'
    )
    .max(50, 'Last name must not exceed 50 characters'),
  email: Yup.string()
    .required('Please enter your email')
    .matches(EMAIL_REGX, 'Invalid email address')
    .max(50, 'Email must not exceed 50 characters'),
  password: Yup.string()
    .required('Please enter your password')
    .min(6, 'Password length must be at least 6 characters long!')
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      'Password must contain at least one alphabetic character and one special character'
    )
    .max(50, 'password must not exceed 50 characters'),
  confirm_password: Yup.string()
    .required('Please enter your confirm password')
    .oneOf([Yup.ref('password'), null], 'Password must match'),
  check: Yup.boolean().oneOf(
    [true],
    'Please accept the terms and privacy policy before get started!'
  ),
})

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  confirm_password: '',
  check: false,
}

function Register() {
  const Navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const { recaptchaToken, setRecaptchaToken } = useContext(GlobalContext)
  const key = process.env.REACT_APP_RECAPTCHA_KEY
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, action) => {
      if (!recaptchaToken) {
        Swal.fire({
          icon: 'error',
          title: 'Please verify you are not a robot',
        })
        return
      }
      setLoading(true)
      await Api.register(values)
        .then((response) => {
          setLoading(false)
          Cookies.set('email', values.email)
          action.resetForm()
          Navigate(`/verify-otp/${values.email}`)
        })
        .catch((error) => {
          setLoading(false)
          const errorMessage = getErrorMessage(error.response.status)
          if (error.response.status === 400) {
            setEmailError(true)
          } else if (errorMessage) {
            Swal.fire({
              icon: 'error',
              text: errorMessage,
              customClass: {
                confirmButton: 'custom-confirm-button',
              },
            })
          }
        })
    },
  })
  useEffect(() => {
    resetForm({
      values: {
        ...initialValues, // spread existing initial values
        email: '', // reset email to empty
        password: '', // reset password to empty
      },
    })
  }, [resetForm])

  const onChangeRecaptcha = (token) => {
    setRecaptchaToken(token)
  }
  return (
    <>
      <div className='custom-background '>
        <div className='flex justify-center items-center'>
          <div className='flex border-1 border-[#464646] w-fit m-6 rounded-lg'>
            <div className='relative md:p-8 xs:p-0 xs:px-[5px] w-full rounded-lg shadow-2xl'>
              <span className=' flex justify-center mt-2 mb-8'>
                <LexaLogoIcon/>
              </span>
              <form
                onSubmit={handleSubmit}
                className='flex flex-col justify-center '
              >
                <div className='headings'>
                  <h3 className='text-2xl font-medium text-white mb-2 mt-4'>
                    Create an account
                  </h3>
                  <span className='flex '>
                    <RegisterSpeakerIcon/>
                    <p className='text-xl text-white ml-2 font-[Poppins '>
                      Lexa 2.0 : &nbsp; 14-Day Free Trial
                    </p>
                  </span>
                </div>
                <div className='xs:block md:flex flex-row md:gap-x-3 xs:gap-0'>
                  <div className='my-2'>
                    <Input
                      type='text'
                      placeholder='Enter Your First Name'
                      name='firstname'
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.firstname}
                      text='First Name'
                    />
                    {errors.firstname && touched.firstname ? (
                      <FormErrors error={errors.firstname} />
                    ) : null}
                  </div>
                  <div className='my-2 '>
                    <Input
                      type='text'
                      placeholder='Enter Your Last Name'
                      name='lastname'
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.lastname}
                      text='Last Name'
                    />
                    {errors.lastname && touched.lastname ? (
                      <FormErrors error={errors.lastname} />
                    ) : null}
                  </div>
                </div>
                <div className='relative my-2'>
                  <div className='relative'>
                    <Input
                      type='text'
                      placeholder='Enter your email'
                      name='email'
                      value={values.email}
                      onChange={(e) => {
                        handleChange(e)
                        setEmailError(false)
                      }}
                      onBlur={handleBlur}
                      errors={errors.email}
                      text='Email'
                      autoComplete='off' // Prevent auto-fill
                    />
                    <span className='absolute right-3 md:top-[32px] xs:top-[38px] inset-y-0 flex items-center cursor-pointer text-gray-400'>
                      <LoginMessageIcon/>
                    </span>
                  </div>

                  {errors.email && touched.email ? (
                    <p className='mt-1 text-sm text-red-500'>
                      <FormErrors error={errors.email} />
                    </p>
                  ) : null}

                  {emailError && values.email ? (
                    <p className='mt-1 text-sm text-red-500'>
                      <FormErrors error='User with this email already exists' />
                    </p>
                  ) : (
                    ''
                  )}
                </div>

                <div className='my-2'>
                  <div className='w-full relative'>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Enter Your Password'
                      name='password'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.password}
                      text='Password'
                      autoComplete='off' // Prevent auto-fill
                    />
                    {showPassword ? (
                      <AiFillEyeInvisible
                        onClick={() => setShowPassword(false)}
                        className='absolute right-3 md:top-[67%] xs:top-[70%] transform -translate-y-1/2 cursor-pointer text-gray-400'
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setShowPassword(true)}
                        className='absolute right-3 md:top-[67%] xs:top-[70%] transform -translate-y-1/2 cursor-pointer text-gray-400'
                      />
                    )}
                  </div>
                  {errors.password && touched.password ? (
                    <FormErrors error={errors.password} />
                  ) : null}
                </div>
                <div className='my-2'>
                  <div className='w-full relative'>
                    <Input
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder='Enter Your Password'
                      name='confirm_password'
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.confirm_password}
                      text='Confirm Password'
                    />
                    {showConfirmPassword ? (
                      <AiFillEyeInvisible
                        onClick={() => setShowConfirmPassword(false)}
                        className='absolute right-3 md:top-[67%] xs:top-[70%] transform -translate-y-1/2 cursor-pointer text-gray-400'
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setShowConfirmPassword(true)}
                        className='absolute right-3 md:top-[67%] xs:top-[70%] transform -translate-y-1/2 cursor-pointer text-gray-400'
                      />
                    )}
                  </div>
                  {errors.confirm_password && touched.confirm_password ? (
                    <FormErrors error={errors.confirm_password} />
                  ) : null}
                </div>
                <div className='flex items-start mt-2'>
                  <input
                    type='checkbox'
                    name='check'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.check}
                    className='cursor-pointer w-[17px]'
                  />
                  <span className=' text-white font-[Poppins] w-full text-[10px]  ml-[2px]'>
                    By signing up you acknowledge that content is generated by
                    AI and agree to our
                    <Link to='/terms-and-conditions' className='mx-1'>
                      terms of use
                    </Link>
                    &
                    <Link to='/privacy-policy' className='mx-1'>
                      privacy policy.
                    </Link>
                  </span>
                </div>
                <div className='mt-3'>
                  <Turnstile
                    sitekey={key} // Replace with your actual site key
                    onVerify={onChangeRecaptcha}
                    options={{ theme: 'light' }} // You can set theme to 'light' or 'dark'
                  />
                </div>
                {errors.check && touched.check ? (
                  <FormErrors error={errors.check} />
                ) : null}
                <div className='divider flex flex-row text-white mt-3'>
                  <p>Sign up Though Social Media</p>
                  <span className='border-1 h-[2px] border-[#A4A4A4] opacity-10 ml-2 w-1/2 mt-[12px]'></span>
                </div>
                <span className='flex flex-row gap-x-2 mb-2'>
                  <GoogleAuth authIcon={<AuthGoogleIcon/>} />
                  <FacebookAuth authIcon={<AuthFacebookIcon/>} />
                </span>

                <div className='my-2'>
                  <SubmitButton
                    title='Create Account'
                    handleSubmit={handleSubmit}
                    loading={loading}
                  />
                </div>
              </form>
              <div className='xs:p-[12px] md:p-[2px] text-[9px] md:text-[10px]  text-white '>
                Have an account?{' '}
                <Link
                  to='/login'
                  className='font-semibold text-decoration-none text-[#1f88fe]'
                >
                  Sign in
                </Link>{' '}
                to get started!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register

function getErrorMessage(statusCode) {
  const errorItem = ErrorMessages.find((item) => item[statusCode.toString()])
  return errorItem
    ? errorItem[statusCode.toString()]
    : 'An unknown error occurred.'
}
