import React from 'react'

const JudgmentSkeleton = () => {
    return (
      <div className="flex flex-col items-start justify-start w-full  pt-2 pb-0 border-2 rounded-3xl mb-4 border-gray-200 ">
        <div className="flex w-full items-center justify-between border-b-[1px] border-[#CFCFCF] h-[65px] animate-pulse px-5">
          <div className="w-[85%] h-4 bg-gray-300 rounded-md"></div>
          <div className="w-[13%] h-8 bg-[#20808D] rounded-full"></div>
        </div>
  
        <div className="md:flex xs:block items-center justify-start gap-2 pt-[15px] animate-pulse px-5">
          <div className="w-32 h-4 bg-gray-300 rounded-md"></div>
          <div className="w-32 h-4 bg-gray-300 rounded-md"></div>
          <div className="w-96 h-4 bg-gray-300 rounded-md"></div>
        </div>
  
        <div className="md:flex xs:block items-center justify-start gap-2 pt-[5px] animate-pulse px-5">
          <div className="w-32 h-4 bg-gray-300 rounded-md"></div>
          <div className="w-32 h-4 bg-gray-300 rounded-md"></div>
        </div>
  
        <div className="w-full mt-[15px] pb-[75px] flex items-center justify-between bg-[#d5d8d9] rounded-[0px_0px_22px_22px] animate-pulse">
        </div>
      </div>
    );
  };
  
export default JudgmentSkeleton
