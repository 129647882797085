import React, { useEffect, useState,useContext } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../styles/LandingPage.css'
import Header from '../components/landingPage/Header'
import About from '../components/landingPage/About'
import Pricing from '../components/landingPage/Pricing'
import Features from '../components/landingPage/Features'
import BlogsFooter from '../components/landingPage/BlogsFooter'
import Testimonials from '../components/landingPage/Testimonials'
import Benefits from '../components/landingPage/Benefits'
import LatestBlogs from '../components/landingPage/LatestBlogs'
import OurMission from '../components/landingPage/OurMission'
import { useNavigate } from 'react-router-dom'
import ApiController from '../components/Blogs/BlogSite/utils/network/Api/api'
import AffordableLexaComponent from '../components/landingPage/AffordableLexaComponent'
import LexaVideo from '../components/landingPage/LexaVideo'
import {Api} from '../api'
import GlobalContext from '../context/GlobalContext'
function LandingPage() {
  const navigate = useNavigate()

  const [blogsLoading, setBlogsLoading] = useState(false)
  const [latestBlogs, setLatestBlogs] = useState([])
  const {
    plans,
    setPlans,
  } = useContext(GlobalContext)


  //Payment Plans Api Calling
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await Api.fetchPlans(); // Call the fetchPlans function
        const data = response?.data?.data;
        data.sort((a, b) => parseFloat(a.amount.replace(/[^0-9.-]+/g, "")) - parseFloat(b.amount.replace(/[^0-9.-]+/g, "")));

        setPlans(data);
      } catch (error) {
        console.error('Failed to fetch plans:', error);
      }
    };
    fetchPlans(); // Call fetchPlans on component mount
  }, []);
  const handlePath = (planName) => {
    sessionStorage.setItem('selectedPlan', planName)
    navigate('/login?redirect=payment')
  }



  useEffect(() => {
    AOS.init({ duration: 2000 })

//Blogs api 
    
    const fetchBlogs = () => {
      setBlogsLoading(true)
      const payload = {} // Adjust the payload if needed
      ApiController.fetchBlogsListPageCall(payload, (response) => {
        if (response && response.success && response.data) {
          setLatestBlogs(response.data.slice(0, 3)) // Only take the latest three blogs
        } else {
          console.error('Failed to fetch blogs:', response)
        }
        setBlogsLoading(false)
      })
    }

    fetchBlogs()
  }, [])

  const onClickToRedirect = (blog) => {
    const endURL = blog?.slug || blog?.end_url
    navigate(`/blogs-view/${endURL}`)
  }



  return (
    <div className='landing-page bg-black text-white'>
      <Header />
      <About />
      <LexaVideo/>
      <Features />
      <Testimonials />
      <Benefits />
      <OurMission/>
      <AffordableLexaComponent handlePath={handlePath}/>
      <Pricing handlePath={handlePath}  plan={plans}/>
      {/* {blogsLoading ? (
        <div>Loading blogs...</div>
      ) : (
        <LatestBlogs
          latestBlogs={latestBlogs}
          onClickToRedirect={onClickToRedirect}
        />
      )} */}
      <BlogsFooter />
    </div>
  )
}

export default LandingPage
