import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
//custom Style
import '../styles/ConversationView.css'
//Global State
import GlobalContext from '../context/GlobalContext'
//customComponent
import Input from '../components/Input'
//other library
import ReactMarkdown from 'react-markdown'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { Viewer } from '@react-pdf-viewer/core'
import { pdfjs } from 'react-pdf'
import { Worker } from '@react-pdf-viewer/core'

//custom data
import { summaryKeyWords } from '../staticData/constants'
//icons
import Avatar from '@mui/material/Avatar'
import { IconAi } from '../assets/icons'
import { copy } from '../assets'
import { MdOutlineCheck } from 'react-icons/md'
import { AiOutlineClose } from 'react-icons/ai'

//import validation functions
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import {
  customComponents,
  cleanText,
  addSpacesToSentence,
  summaryVisible,
  handleCopyClick,
  removeSummaryKeywords,
  handleViewPdf,
} from '../utilities/Validation'
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.js',
  import.meta.url
).toString()

//custom style

const ChatBot = (props) => {
  const messageEndRef = useRef(null)
  const [copySuccessArray, setCopySuccessArray] = useState([])
  const [stopGeneration, setStopGeneration] = useState(false)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const { id: Id } = useParams()
  const {
    chatData,
    errorMessage,
    chatLoading,
    themeToggle,
    currentRecievingChat,
    chatLoadingId,
    isChatDataLoading,
  } = useContext(GlobalContext)

  useEffect(() => {
    const scrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'nearest',
    }

    const timer = setTimeout(() => {
      messageEndRef.current?.scrollIntoView(scrollIntoViewOptions)
    }, 50)
    return () => clearTimeout(timer)
  }, [chatData?.length, currentRecievingChat])

  return (
    <>
      <div className='w-[100%] flex items-start  mt-4 justify-start flex-col z-40 '>
        <span className='font-roboto 2xl:text-[22px] text-[15px] font-normal text-[#4f4f4f] dark:text-white'>
          How I can help you?
        </span>
      </div>

      <div className={`conversation-box ${isChatDataLoading ? 'grid' : ''}`}>
        <div className='w-[100%] flex justify-start mt-[10px]'>
          <div className='w-[45px] h-[45px] flex items-center justify-center'>
            <IconAi />
          </div>
          <div className=' w-[100%] m-h-[60px] mt-[-3px] ml-[26px] md:ml-[30px] flex items-start gap-[20px]'>
            <p className='pt-[10px] text-[#333] text-[15px] md:text[18px] 2xl:text-[18px] font-[400] font-roboto dark:text-white'>
              Please provide a brief but detailed description of the legal issue
              for which you would like me to research judgments.
            </p>
          </div>
        </div>
        {isChatDataLoading ? (
          <div className='loader'>
            <div
              className='inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-black align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'
              role='status'
            >
              <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
                Loading...
              </span>
            </div>
          </div>
        ) : chatData && chatData.length > 0 ? (
          chatData?.map((data, chatIndex) => {
            let judgementData = null
            if (
              data.role === 'user' &&
              summaryKeyWords.some((word) =>
                data?.content?.toLowerCase().includes(word.toLowerCase())
              )
            ) {
              summaryVisible.push(true)
            } else if (data.role === 'user') {
              summaryVisible.push(false)
            }
            if (data.role === 'ai') {
              judgementData =
                chatData[chatIndex + 1]?.role === 'judgement'
                  ? chatData[chatIndex + 1]
                  : chatData[chatIndex - 1]?.role === 'judgement'
                  ? chatData[chatIndex - 1]
                  : null
            }
            return (
              <>
                <div key={chatIndex}>
                  {data.role === 'user' && (
                    <div className='sender-div  dark:text-white'>
                      <Avatar
                        src='/broken-image.jpg'
                        sx={{
                          borderRadius: 0,
                          width: '45px',
                          height: '45px',
                        }}
                      />
                      <div className='sender-message'>
                        {data?.content?.split('\n')?.map((paragraph, index) => (
                          <p
                            key={index}
                            className='mb-2 text-[#333] text-[15px] md:text-[18px] xl:text-[17px] font-[500] font-roboto text-justify '
                          >
                            {addSpacesToSentence(paragraph)}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}

                  {data.role === 'ai' && (
                    <>
                      <div className='receiver-div relative'>
                        <div className='img-box md:w-[45px] xs:w-[20px] md:h-[45px] xs:h-[20px]'>
                          <IconAi />
                        </div>
                        <div
                          key={chatIndex}
                          className='general_receiver-message flex flex-col items-start justify-start text-justify mt-[0px]'
                        >
                          {data?.content
                            ?.split('\n')
                            ?.map((paragraph, index) => {
                              let fetchedContent = paragraph
                              fetchedContent = fetchedContent.replace(
                                /^ ?content=("|')/,
                                ''
                              )
                              fetchedContent = fetchedContent.replace(
                                /("|')$/,
                                ''
                              )
                              fetchedContent = fetchedContent.replace(
                                /^content='/,
                                ''
                              )
                              fetchedContent = fetchedContent.replace(
                                /\\n/g,
                                '\n'
                              )
                              return (
                                <>
                                  <ReactMarkdown
                                    components={customComponents}
                                    key={index}
                                  >
                                    {addSpacesToSentence(fetchedContent)}
                                  </ReactMarkdown>
                                  {copySuccessArray[chatIndex] ? (
                                    <div className='absolute top-0 right-[-45px]'>
                                      <MdOutlineCheck
                                        style={{
                                          color: '#212121',
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className='absolute top-0 md:right-[-45px] xs:right-[-12px] contrast-50'>
                                      <img
                                        src={copy}
                                        alt='copyIcon'
                                        className='w-[25px] h-[25px] mt-[5px]'
                                        onClick={() =>
                                          handleCopyClick(
                                            data?.content,
                                            chatIndex,
                                            chatData || [],
                                            summaryVisible || [],
                                            setCopySuccessArray,
                                            copySuccessArray || []
                                          )
                                        }
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  )}
                                </>
                              )
                            })}
                          {judgementData &&
                            summaryVisible[summaryVisible.length - 1] ===
                              true && (
                              <p className='dark:text-white '>
                                Key Judgements:
                              </p>
                            )}
                          <ol
                            className='dark:text-white'
                            style={{ listStyleType: 'decimal' }}
                          >
                            {judgementData &&
                              summaryVisible[summaryVisible.length - 1] &&
                              JSON.parse(judgementData.content)
                                .judgements.filter(
                                  (judgment) => judgment.summary.trim() !== ''
                                )
                                .map((judgment, index) => {
                                  let updatedSummary = removeSummaryKeywords(
                                    judgment.summary
                                  )
                                  updatedSummary = updatedSummary.replace(
                                    /^\s*[-*_]{3,}\s*$/gm,
                                    ''
                                  )
                                  return (
                                    <li key={index}>
                                      <span className='font-semibold dark:text-white flex'>
                                        {judgment.document_number}
                                      </span>
                                      <ReactMarkdown
                                        components={customComponents}
                                        key={index}
                                      >
                                        {updatedSummary}
                                      </ReactMarkdown>
                                    </li>
                                  )
                                })}
                          </ol>
                          <div>
                            {judgementData &&
                              JSON.parse(judgementData.content).judgements.some(
                                (judgment) => judgment.present
                              ) && (
                                <div>
                                  <div className='heading my-4 px-1 pr-2 w-fit bg-gray-100 border-l-4 border-l-[#21808D]'>
                                    <h4>Reference:</h4>
                                  </div>
                                </div>
                              )}
                            {judgementData &&
                              JSON.parse(judgementData.content).judgements.map(
                                (judgment, index) => (
                                  <button
                                    key={index}
                                    onClick={() => handleViewPdf(judgment, setPdfUrl, setOpenModal)}

                                    className='py-2 px-2 mb-4 shadow-md no-underline rounded-lg bg-[#21808D] text-white text-justify font-semibold text-sm border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none mr-2'
                                  >
                                    <span className='mt-2 font-semibold'>
                                      {' '}
                                      {judgment.document_number}
                                    </span>
                                  </button>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )
          })
        ) : (
          <div className='empty-chat'>
            {/* <h1 className="text-black">New chat is here, message me!</h1> */}
          </div>
        )}

        {props.debugMessage && (
          <div className='receiver-div'>
            <div className='img-box md:w-[45px] xs:w-[20px] md:h-[45px] xs:h-[20px]'>
              <IconAi />
            </div>
            <div className='general_receiver-message flex flex-col items-start justify-start text-justify '>
              {props.debugMessage?.split('\n')?.map((paragraph, index) => (
                <ReactMarkdown
                  className='text-[15px] md:text-[16px] dark:text-black'
                  key={index}
                  components={customComponents}
                  children={addSpacesToSentence(paragraph).replace(
                    /<b>(.*?)<\/b>/g,
                    (_, p1) => `**${p1}**`
                  )}
                />
              ))}
            </div>
          </div>
        )}

        {currentRecievingChat &&
          (Id === 'ChatBot' || parseInt(Id) === chatLoadingId) && (
            <>
              <div className='receiver-div relative'>
                <div className='img-box'>
                  <IconAi />
                </div>
                <div className='general_receiver-message of  flex flex-col items-start justify-start text-justify mt-[0px]'>
                  {currentRecievingChat
                    ?.split('\n')
                    ?.map((paragraph, index) => (
                      <>
                        <ReactMarkdown
                          className='text-[15px] md:text-[16px] dark:text-black'
                          key={index}
                          components={customComponents}
                          children={addSpacesToSentence(paragraph).replace(
                            /<b>(.*?)<\/b>/g,
                            (_, p1) => `**${p1}**`
                          )}
                        />
                      </>
                    ))}
                </div>
              </div>
            </>
          )}
        {chatLoading &&
          (Id === 'ChatBot' || parseInt(Id) === chatLoadingId) && (
            <div className='receiver-div d-flex align-items-center'>
              <div className='img-box'>
                <IconAi />
              </div>
              <div className='custom-loader'></div>
            </div>
          )}
        {errorMessage && (
          <div className='receiver-div d-flex align-items-center'>
            <div className='img-box'>
              <IconAi />
            </div>
            <div className='error-box'>{errorMessage}</div>
          </div>
        )}
        <div ref={messageEndRef} />
      </div>

      <div className='input-box'>
        <Input
          debugMessage={props.debugMessage}
          setDebugMessage={props.setDebugMessage}
          cancelTokenSource={cancelTokenSource}
          setCancelTokenSource={setCancelTokenSource}
          stopGeneration={stopGeneration}
          setStopGeneration={setStopGeneration}
        />
        <p className={`mb-0 ${themeToggle ? 'text-white' : 'text-gray-500'}`}>
          The information provided by this chatbot is for general informational
          purposes only and does not constitute legal advice.
        </p>
      </div>
      {openModal && (
        <div
          onClick={() => setOpenModal(false)}
          className='fixed top-0 bottom-0 left-0 right-0 flex items-start justify-center bg-[#767676] bg-opacity-20 backdrop-blur-sm z-[9999] py-10'
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className=' fixed flex flex-col w-[70%] h-[90vh]  bg-[#FCFCF9] overflow-auto custom-scrollbar'
          >
            <div
              className='cursor-pointer w-full flex items-end justify-end pt-2 px-2'
              onClick={() => setOpenModal(false)}
            >
              <AiOutlineClose size={20} className='text-[#13343B]' />
            </div>

            <div className='flex flex-col w-full h-full'>
              <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ChatBot
