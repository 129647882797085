import * as React from 'react'
const LoginMessageIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M27.5 7.49991C27.5 6.12491 26.375 4.99991 25 4.99991H5C3.625 4.99991 2.5 6.12491 2.5 7.49991V22.4999C2.5 23.8749 3.625 24.9999 5 24.9999H25C26.375 24.9999 27.5 23.8749 27.5 22.4999V7.49991ZM25 7.49991L15 13.7499L5 7.49991H25ZM25 22.4999H5V9.99991L15 16.2499L25 9.99991V22.4999Z'
      fill='#888888'
    />
  </svg>
)
export default LoginMessageIcon
