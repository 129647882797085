
import * as React from 'react'
const SearchBarIcon = (props) => (
    <svg
    width='17'
    height='18'
    viewBox='0 0 17 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.4881 15.8753L11.2709 10.6581C12.0805 9.61144 12.5184 8.33175 12.5184 6.98577C12.5184 5.37461 11.8896 3.86389 10.7526 2.72483C9.61551 1.58577 8.10078 0.958984 6.49163 0.958984C4.88248 0.958984 3.36775 1.58778 2.23069 2.72483C1.09163 3.86189 0.464844 5.37461 0.464844 6.98577C0.464844 8.59492 1.09364 10.1097 2.23069 11.2467C3.36775 12.3858 4.88047 13.0126 6.49163 13.0126C7.83761 13.0126 9.11529 12.5746 10.1619 11.767L15.3791 16.9822C15.3944 16.9975 15.4126 17.0096 15.4326 17.0179C15.4526 17.0262 15.474 17.0305 15.4956 17.0305C15.5173 17.0305 15.5387 17.0262 15.5587 17.0179C15.5787 17.0096 15.5969 16.9975 15.6122 16.9822L16.4881 16.1083C16.5034 16.093 16.5155 16.0749 16.5238 16.0549C16.5321 16.0349 16.5363 16.0134 16.5363 15.9918C16.5363 15.9702 16.5321 15.9487 16.5238 15.9287C16.5155 15.9087 16.5034 15.8906 16.4881 15.8753ZM9.67377 10.1679C8.82199 11.0177 7.69297 11.4858 6.49163 11.4858C5.29029 11.4858 4.16127 11.0177 3.30949 10.1679C2.45971 9.31613 1.99163 8.18711 1.99163 6.98577C1.99163 5.78443 2.45971 4.6534 3.30949 3.80363C4.16127 2.95385 5.29029 2.48577 6.49163 2.48577C7.69297 2.48577 8.824 2.95184 9.67377 3.80363C10.5235 4.65541 10.9916 5.78443 10.9916 6.98577C10.9916 8.18711 10.5235 9.31814 9.67377 10.1679Z'
      fill='black'
      fill-opacity='0.45'
    />
  </svg>
)
export default SearchBarIcon
