import React, { useContext , useEffect} from 'react';
import GlobalContext from '../context/GlobalContext';
import { SearchBarIcon } from '../assets/icons';

function SearchQuery() {
  const { sQuery, setSearchPost,selectedTab } = useContext(GlobalContext);

  const handleSearch = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    setSearchPost(''); 
  }, [selectedTab]);

  return (
    <div>
      <div className="search-container">
      <form className="flex border-2 border-[#D9D9D9] rounded-full overflow-hidden justify-center">
        <input
          type="text"
          className="search-input px-3 py-2 md:w-96 xs:w-44 outline-none"
          placeholder="Search..."
          value={sQuery}
          onChange={(e) => setSearchPost(e.target.value)}
        />
        <button
          type="submit"
          className="flex items-center justify-center px-4 border-l bg-[#D9D9D9] "
          onClick={handleSearch}
        >
         <SearchBarIcon/>
        </button>
      </form>
    </div>
    </div>
  );
}

export default SearchQuery;




