
import * as React from 'react'
const RefundPolicyIcon = (props) => (
    <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      d='M7.76405 7.11979C7.96487 6.54892 8.36124 6.06755 8.88297 5.76093C9.4047 5.4543 10.0181 5.34222 10.6146 5.44452C11.211 5.54683 11.752 5.85693 12.1417 6.31989C12.5314 6.78285 12.7447 7.3688 12.7438 7.97396C12.7438 9.68229 10.1813 10.5365 10.1813 10.5365M10.2497 13.9531H10.2582M18.7913 9.68229C18.7913 14.3997 14.9671 18.224 10.2497 18.224C5.53224 18.224 1.70801 14.3997 1.70801 9.68229C1.70801 4.96486 5.53224 1.14062 10.2497 1.14062C14.9671 1.14062 18.7913 4.96486 18.7913 9.68229Z'
      stroke='#20808D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default RefundPolicyIcon
