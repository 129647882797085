// Library Imports
import React from 'react'
import { UnCheckedCheckboxIcon,CheckedCheckBoxIcon } from '../../../assets/icons'
import { Checkbox as MuiCheckbox, Box, FormLabel } from '@mui/material'

const CustomCheckBox = ({ label, checked, value, onChange, name }) => {
  return (
    <React.Fragment>
      <Box
        component={'div'}
        width='max-content'
        display='flex'
        alignItems='center'
        gap='8px'
      >
        <MuiCheckbox
          icon={<UnCheckedCheckboxIcon />}
          checkedIcon={<CheckedCheckBoxIcon />}
          onChange={onChange}
          checked={checked}
          value={value}
          name={name}
          sx={{ padding: '0px' }}
          placeholder={label}
          // className='checkbox'
        />
        <FormLabel sx={{ fontSize: '13px', color: 'black' }}>{label}</FormLabel>
      </Box>
    </React.Fragment>
  )
}
export default CustomCheckBox
