import React, { useContext, useEffect, useState, useRef } from 'react'
import { TextFileIcon,DownloadIcon } from "../assets/icons";
import SearchQuery from './SearchQuery'
import GlobalContext from '../context/GlobalContext'
import '../styles/statute.css'
import { Api } from '../api'
import Cookies from 'js-cookie'
import Pagination from './Pagination'
import CustomModal from './shared/CustomModal'
import { Viewer } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { pdfjs } from 'react-pdf'
import StatuteSkeleton from './shared/Sekelton/StatuteSkeleton'
import { Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.js',
  import.meta.url
).toString()

function Statute() {
  const { sQuery } = useContext(GlobalContext)
  const [statutes, setStatutes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [nextUrl, setNextUrl] = useState(null) // For the next page URL
  const [previousUrl, setPreviousUrl] = useState(null) // For the previous page URL
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const scrollToTopRef = useRef(null);  // Reference for the top of the container

  const handlePageChange = (page) => {
    setIsLoading(true)

    if (typeof page === 'number') {
      setCurrentPage(page)
    } else if (page === 'next' && nextUrl) {
      const offset = new URLSearchParams(nextUrl.split('?')[1]).get('offset')
      setCurrentPage(Math.floor(parseInt(offset, 10) / 30) + 1)
    } else if (page === 'pre' && previousUrl) {
      const offset = new URLSearchParams(previousUrl.split('?')[1]).get(
        'offset'
      )
      setCurrentPage(Math.floor(parseInt(offset, 10) / 30) + 1)
    } else {
      setIsLoading(false)
      console.error('Error: The URL for the requested direction is null.')
    }
  }

  useEffect(() => {
    const fetchStatutes = async () => {
      setIsLoading(true);
  
      try {
        const token = Cookies.get('token');
        if (token) {
          const offset = (currentPage - 1) * 30;
          const url = sQuery
            ? `/bot/get-statues?search=${sQuery}&limit=30&offset=${offset}`
            : `/bot/get-statues?limit=30&offset=${offset}`;
  
          const response = await Api.statutePagination(token, url);
  
          if (response.status === 200 && response.data) {
            setStatutes(response?.data?.results);
            setNextUrl(response?.data?.next);
            setPreviousUrl(response?.data?.previous);
            const calculatedTotalPages = Math.ceil(response?.data?.count / 30);
            setTotalPages(calculatedTotalPages);
          }
        }
      } catch (error) {
        const errorMessage = error?.response;
        // if (
        //   errorMessage?.status === 401 &&
        //   errorMessage?.data?.message === 'Invalid token.'
        // ) {
        //   Cookies.remove('token');
        //   const message = encodeURIComponent("Session expired"); // Static message
        //   window.location.href = `/login?error=${message}`; 
        // } else {
        //   console.error(errorMessage);
        // }
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchStatutes();
  }, [sQuery, currentPage]);

  useEffect(() => {
    if (scrollToTopRef.current) {
      scrollToTopRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [statutes]);


  const openModalWithPdf = (url) => {
    setPdfUrl(url)
    setIsModalOpen(true)
  }

  const downloadPdf = async (url, title) => {
    try {
      const response = await fetch(url)
      const blob = await response.blob()
      const blobUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', `${title}.pdf`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } catch (error) {
      console.error('Error downloading PDF:', error)
    }
  }

  return (
    <div>
     <div ref={scrollToTopRef}></div> {/* Invisible div for scrolling */}
      <div
        className={`main-statute xs:pl-0 pr-[40px] xs:pr-0 mt-10 mr-11 ${
          statutes?.length !== 0 ? 'center-content' : ''
        }`}
      >
        <div  className='search-container'>
          <div className='heading'>
            <p className='font-semibold text-center text-2xl capitalize dark:text-white'>
              Find statutes here!
            </p>
            <div className='flex items-center justify-center '>
              <SearchQuery />
            </div>
          </div>
        </div>

        {/* Display searched chapter on top */}
        {sQuery && (
          <div className=' text-center mt-3'>
            {statutes?.length === 0 && statutes?.length !== sQuery ? (
              <h5>Sorry, no results found</h5>
            ) : (
              <h5>Search Results for "{sQuery}"</h5>
            )}
          </div>
        )}

        <div className='statute-content grid lg:grid-cols-4 md:grid-cols-2 xs:grid-cols-1 gap-4 w-full'>
          {isLoading
            ? // Show skeletons when data is being loaded
              Array.from({ length: 8 }).map((_, index) => (
                <StatuteSkeleton key={index} />
              ))
            : statutes?.map((item, index) => (
                <div
                  key={index}
                  className='flex flex-col border bg-white shadow rounded-lg overflow-hidden my-4'
                >
                  <div
                    className='p-4 flex flex-col flex-grow cursor-pointer'
                    onClick={() => openModalWithPdf(item?.url)}
                  >
                    <h3 className='text-lg font-semibold mt-2'>
                      {item?.title}
                    </h3>
                    <p className='flex-grow'>{item?.description}</p>
                  </div>
                  <div className='my-btn flex justify-between items-center bg-[#20808D] border-t-2'>
                    <div className=' p-4'>
                      <span className='text-white text-base font-semibold flex items-center'>
                       <TextFileIcon/>
                        <span className='ml-2 xs:ml-0 cursor-default'>
                          Download PDF
                        </span>
                      </span>
                    </div>
                    <button
                      onClick={() => downloadPdf(item?.url, item?.title)}
                      download={`${item?.title}.pdf`}
                      className='text-white bg-white p-[18px]'
                    >
                      <DownloadIcon/>
                    </button>
                  </div>
                </div>
              ))}
        </div>
        {!isLoading && statutes?.length !== 0 && (
          <div className='px-4 py-3'>
            {' '}
            <Pagination
              previousUrl={previousUrl}
              nextUrl={nextUrl}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
        <CustomModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          url={statutes.url}
          handleDownload={() => downloadPdf(pdfUrl, 'Document')}
        >
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
            <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        </CustomModal>
      </div>
    </div>
  )
}

export default Statute
