
import * as React from 'react'
const PrivacyPolicyIcon = (props) => (
    <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_1414_5452)'>
      <path
        d='M1.70801 14.6143L10.2497 18.8851L18.7913 14.6143M1.70801 10.3434L10.2497 14.6143L18.7913 10.3434M10.2497 1.80176L1.70801 6.07259L10.2497 10.3434L18.7913 6.07259L10.2497 1.80176Z'
        stroke='#20808D'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1414_5452'>
        <rect
          width='20.5'
          height='20.5'
          fill='white'
          transform='translate(0 0.09375)'
        />
      </clipPath>
    </defs>
  </svg>
)
export default PrivacyPolicyIcon
