import React from 'react';

function QuizSkeleton() {
  return (
    <div className="quiz-wrapper md:ml-0 xs:ml-[18px] animate-pulse">
      {/* Loop over 4 skeleton questions */}
      {Array.from({ length: 2 }).map((_, questionIndex) => (
        <div key={questionIndex} className="quizData mt-4 mb-[60px]">
          <div className="quiz-Question md:ml-6 xs:ml-0">
            {/* Skeleton for question title */}
            <h4 className="bg-gray-300 h-6 w-1/4 mb-4 rounded"></h4>
            <div className="question text-lg space-y-2 sx:text-[16px] dark:text-white md:text-[18px]">
              <p className="bg-gray-300 h-4 w-3/4 mb-2 rounded"></p>
              <p className="bg-gray-300 h-4 w-1/2 rounded"></p>
            </div>
          </div>

          {/* Skeleton for options (4 options per question) */}
          {Array.from({ length: 4 }).map((_, optionIndex) => (
            <div
              key={optionIndex}
              className="quiz-answer bg-[#f4f3f6] inline-block rounded py-2 pl-4 pr-6 my-2 md:ml-6 xs:ml-[6px] md:min-w-[470px] xs:min-w-[280px]"
            >
              <div className="quiz-option flex items-center">
                <span className="bg-gray-300 h-8 w-8 rounded-full mr-4"></span>
                <div className="left ml-2 mt-3 pr-3">
                  <p className="bg-gray-300 h-4 w-3/4 rounded"></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default QuizSkeleton;
