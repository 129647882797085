import React from 'react'
import { Link } from 'react-router-dom' // make sure you have react-router-dom installed
import { LexaLogoHomeIcon } from '../assets/icons'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
const TermsAndConditions = () => {
  return (
    <div className='container mx-auto px-4 py-6'>
      <Link to='/'>
        {' '}
        <LexaLogoHomeIcon />
      </Link>
      <div className='max-w-3xl mx-auto bg-white p-6 shadow rounded'>
        <div className='mt-8 justify-start flex'>
          <span>
            {' '}
            <ArrowCircleLeftOutlinedIcon />
          </span>
          <Link to='/' className='text-black hover:underline'>
            Go back
          </Link>
        </div>
        <h1 className='text-3xl font-bold text-center'>TERMS AND CONDITIONS</h1>
        <div className='space-y-6'>
          <section>
            <h2 className='text-lg font-semibold'>1. INTRODUCTION</h2>
            <p>Welcome to LEXA ("Company", "we", "our", "us")!</p>
            <p>
              These Terms of Service ("Terms", "Terms of Service") govern your
              use of our platform as well as the websites, software, and other
              products and services, including any web browser extensions,
              provided by or otherwise owned or controlled by LEXA website
              located at Karachi (together or individually "Service") operated
              by LEXA.
            </p>
            <p>
              Our Privacy Policy also governs your use of our Service and
              explains how we collect, safeguard and disclose information that
              results from your use of our web pages.
            </p>
            <p>
              Your agreement with us includes these Terms and our Privacy Policy
              (“Agreements”). You acknowledge that you have read and understood
              Agreements, and agree to be bound of them.
            </p>
            <p>
              If you do not agree with (or cannot comply with) Agreements, then
              you may not use the Service, but please let us know by emailing at
              contact@lexa.lawyer so we can try to find a solution. These Terms
              apply to all visitors, users and others who wish to access or use
              Service.
            </p>
            {/* ... more content ... */}
          </section>
          <section>
            <h2 className='text-lg font-semibold'>2. COMMUNICATIONS</h2>

            <p>
              By using our Service, you agree to subscribe to newsletters,
              marketing or promotional materials and other information we may
              send. However, you may opt out of receiving any, or all, of these
              communications from us by following the unsubscribe link or by
              emailing at contact@lexa.lawyer
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>3. PRIVACY</h2>

            <p>
              Please read our Privacy Policy, which is hereby incorporated by
              reference to these Terms, applies to your use of the Service, and
              sets out terms on which we collect, use, and disclose your
              personal information.
            </p>
          </section>
          {/* ... other sections ... */}
          <section>
            <h2 className='text-lg font-semibold'>4. PROHIBITED USES</h2>
            <p>
              You may use Service only for lawful purposes and in accordance
              with Terms. You agree not to use Service:
            </p>
            <ol className='list-decimal list-inside pl-6 ' type='i'>
              <li className='my-1'>
                In any way that violates any applicable national or
                international law or regulation.
              </li>
              <li className='my-1'>
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content or otherwise.
              </li>
              <li className='my-1'>
                To transmit, or procure the sending of, any advertising or
                promotional material, including any “junk mail”, “chain letter”,
                “spam”, or any other similar solicitation.
              </li>
              <li className='my-1'>
                To infringe on any proprietary rights of any party, including
                patent, trademark, trade secret, copyright, right of publicity
                or other rights.
              </li>
              <li className='my-1'>
                To impersonate any person or entity including Lexa and its
                employees or representatives.
              </li>
              <li className='my-1'>
                Violating the privacy of any third person.
              </li>
              <li className='my-1'>
                To spread or use false information and features.
              </li>
              <li className='my-1'>
                In a manner that harasses, threatens, bullies, or violates the
                rights of others; or
              </li>
              {/* ... other items ... */}
            </ol>
            <p>Additionally, you agree not to:</p>
            <ol className='list-decimal list-inside pl-6' type='i'>
              {/* ... other items ... */}
              <li className='my-1'>
                Use Service in any manner that could disable, overburden,
                damage, or impair Service or interfere with any other party’s
                use of Service, including their ability to engage in real time
                activities through Service.
              </li>
              <li className='my-1'>
                Use any robot, spider, or other automatic device, process, or
                means to access Service for any purpose, including monitoring or
                copying any of the material on Service.
              </li>
              <li className='my-1'>
                Use any manual process to monitor or copy any of the material on
                Service or for any other unauthorized purpose without our prior
                written consent.
              </li>
              <li className='my-1'>
                Use any device, software, or routine that interferes with the
                proper working of Service.
              </li>
              <li className='my-1'>
                Introduce any viruses, trojan horses, worms, logic bombs, or
                other material which is malicious or technologically harmful.
              </li>
              <li className='my-1'>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of Service, the server on which Service is
                stored, or any server, computer, or database connected to
                Service.
              </li>
              <li className='my-1'>
                Attack Service via a denial-of-service attack or a distributed
                denial-of-service attack.
              </li>
              <li className='my-1'>
                Take any action that may damage or falsify Lexa’s rating.
              </li>
              <li className='my-1'>
                Otherwise attempt to interfere with the proper working of
                Service.
              </li>
              {/* ... more items ... */}
            </ol>
          </section>
          <section>
            {' '}
            <h2 className='text-lg font-semibold'>5. NOT LEGAL SERVICES:</h2>
            <p>
              Any service of Lexa or any act within its service does not
              constitute the provision of legal services. The content provided
              by Lexa, including any legal documents, templates, research or
              text generated, is for informational purposes only and does not
              constitute legal advice or establish an attorney-client
              relationship between you and Lexa. You acknowledge that the
              content does not cover all possible legal situations, and it might
              not be suitable for your specific legal matter. We strongly
              recommend you seek the advice of a qualified attorney licensed to
              practice in your jurisdiction for your particular situation.
              Moreover, you agree to:
            </p>
            <ol className='list-decimal list-inside pl-6'>
              <li className='my-1'>
                take full responsibility for ensuring that any information you
                provide to Lexa is true, accurate and complete;
              </li>
              <li className='my-1'>
                acknowledge that Lexa does not constitute the provision of legal
                services;
              </li>
              <li className='my-1'>
                take full responsibility for documents and information that you
                get from Lexa and make any legal decisions yourself;
              </li>
              <li className='my-1'>
                get, any information provided by Lexa reviewed by a licensed
                professional before being sent to Clients, other lawyers, judges
                or any other person;
              </li>
              <li className='my-1'>
                use Lexa for its intended purposes only and not to use Lexa for
                any illicit purposes including to disseminate hate speech or
                similar views or any other illegal activities;
              </li>
              {/* ... other items ... */}
            </ol>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>6. USER DATA:</h2>

            <p>
              You hereby grant, and represent and warrant that you have all
              rights necessary to grant, all rights and licenses to the User
              Data required for Lexa and its service providers to provide the
              Service. Lexa shall have no right to sublicense or resell User
              Data, except however, that you agree that it may collect, analyze,
              and use data derived from User Data, which may include Personal
              Data and/or information collected from or about an individual but
              which does not identify the individual personally, as well as data
              about you, and other Users’ access and use of the Service, for
              purposes of operating, analyzing, improving, or marketing the
              Service and any related services.{' '}
            </p>
            <p>
              If we share or publicly disclose information (e.g., in marketing
              materials, or in application development) that is derived from
              User Data, such data will be aggregated or anonymized to
              reasonably avoid identification of a specific individual or the
              User.{' '}
            </p>
            <p>
              You further agree that we will have the right, both during and
              after the term of these Terms, to use, store, transmit,
              distribute, modify, copy, display, sublicense, and create
              derivative works of the anonymized, aggregated data.
            </p>
            <p>
              You acknowledge that we do not manage or control the user data
              that you access, store or distribute through the service, and
              accepts no responsibility or liability for that information
              regardless of whether such user data is transmitted to or by you
              in breach of these terms
            </p>
          </section>

          <section>
            <h2 className='text-lg font-semibold'>7. ACCOUNTS</h2>

            <p>
              When you create an account with us, you guarantee that you are
              above the age of 18, and that the information you provide us is
              accurate, complete, and current at all times. Inaccurate,
              incomplete, or obsolete information may result in the immediate
              termination of your account on Service{' '}
            </p>
            <p>
              You are responsible for maintaining the confidentiality of your
              account and password, including but not limited to the restriction
              of access to your computer and/or account. You agree to accept
              responsibility for any and all activities or actions that occur
              under your account and/or password, whether your password is with
              our Service or a third-party service. You must notify us
              immediately upon becoming aware of any breach of security or
              unauthorized use of your account.{' '}
            </p>
            <p>
              You may not use as a username the name of another person or entity
              or that is not lawfully available for use, a name or trademark
              that is subject to any rights of another person or entity other
              than you, without appropriate authorization. You may not use as a
              username any name that is offensive, vulgar or obscene.
            </p>
            <p>
              We reserve the right to refuse service, terminate accounts,
              suspend or limit your access to your Account, remove or edit
              content, or cancel orders in our sole discretion, without giving
              any reason.
            </p>
          </section>

          <section>
            <h2 className='text-lg font-semibold'>8. SUBSCRIPTION PERIOD</h2>

            <p>
              The Service is available only with a paid Subscription. You will
              be billed in advance on a recurring and periodic basis (such as
              daily, weekly, monthly or annually), depending on the type of
              Subscription plan you select when purchasing the Subscription.
            </p>
            <p>
              At the end of each period, Your Subscription will automatically
              renew under the exact same conditions unless You cancel it or Lexa
              cancels it.{' '}
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>9. FEE CHANGES</h2>

            <p>
              The Company, in its sole discretion and at any time, may modify
              the Subscription fees. Any Subscription fee change will become
              effective at the end of the then-current Subscription period.
            </p>
            <p>
              The Company will provide You with reasonable prior notice of any
              change in Subscription fees to give You an opportunity to
              terminate Your Subscription before such change becomes effective.{' '}
            </p>
            <p>
              Paid Subscription fees are non-refundable, certain refund requests
              for Subscriptions may be considered by the Company on a
              case-by-case basis and granted at the sole discretion of the
              Company.
            </p>
          </section>

          <section>
            <h2 className='text-lg font-semibold'>10. INTELLECTUAL PROPERTY</h2>

            <p>
              Service and its original content (excluding Content provided by
              users), features and functionality are and will remain the
              exclusive property of Lexa and its licensors. Our trademarks may
              not be used in connection with any product or service without Our
              prior written consent.
            </p>
          </section>

          <section>
            <h2 className='text-lg font-semibold'>11. COPYRIGHT POLICY</h2>

            <p>
              We respect the intellectual property rights of others. It is our
              policy to respond to any claim that Content posted on Service
              infringes on the copyright or other intellectual property rights
              (“Infringement”) of any person or entity.
            </p>
            <p>
              If you are a copyright owner, or authorized on behalf of one, and
              you believe that the copyrighted work has been copied in a way
              that constitutes copyright infringement, please submit your claim
              via email to contact@lexa.lawyer, with the subject line:
              “Copyright Infringement” and include in your claim a detailed
              description of the alleged Infringement.
            </p>
            <p>
              You may be held accountable for damages (including costs and
              attorneys’ fees) for misrepresentation or bad-faith claims on the
              infringement of any Content found on and/or through Service on
              your copyright.
            </p>
          </section>

          <section>
            <h2 className='text-lg font-semibold'>
              12. ERROR REPORTING AND FEEDBACK
            </h2>

            <p>
              You may provide us either directly at contact@lexa.lawyer with
              information and feedback concerning errors, suggestions for
              improvements, ideas, problems, complaints, and other matters
              related to our Service (“Feedback”). You acknowledge and agree
              that: (i) you shall not retain, acquire or assert any intellectual
              property right or other right, title or interest in or to the
              Feedback; (ii) We may have development ideas similar to the
              Feedback; (iii) Feedback does not contain confidential information
              or proprietary information from you or any third party; and (iv)
              We are not under any obligation of confidentiality with respect to
              the Feedback. You grant Lexa and its affiliates an exclusive,
              transferable, irrevocable, free-of-charge, sub-licensable,
              unlimited and perpetual right to use (including copy, modify,
              create derivative works, publish, distribute and commercialize)
              Feedback in any manner and for any purpose.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>
              13. LINKS TO OTHER WEB SITES
            </h2>
            <p>
              Our Service may contain links to third party web sites or services
              that are not owned or controlled by Lexa and it has no control
              over, and assumes no responsibility for the content, privacy
              policies, or practices of any third-party web sites or services.
              We do not warrant the offerings of any of these
              entities/individuals or their websites.
            </p>
            <p>
              You acknowledge and agree that company shall not be responsible or
              liable, directly or indirectly, for any damage or loss caused or
              alleged to be caused by or in connection with use of or reliance
              on any such content, goods or services available on or through any
              such third-party web sites or services.
            </p>
            <p>
              We strongly advise you to read the terms of service and privacy
              policies of any third-party web sites or services that you visit.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>
              14. DISCLAIMER OF WARRANTY:
            </h2>

            <p>
              These services are provided by company on an “AS IS” and “AS
              AVAILABLE” basis and with all faults and defects without warranty
              of any kind. We make no representations or warranties of any kind,
              express or implied, as to the operation of their services, or the
              information, content or materials included therein. You expressly
              agree that your use of these services, their content, and any
              services or items obtained from us is at your sole risk.
            </p>
            <p>
              Neither Lexa nor any person associated with Lexa makes any
              warranty or representation with respect to the completeness,
              security, reliability, quality, accuracy, or availability of the
              services
            </p>
            <p>
              Without limiting the foregoing, neither the Company nor any of the
              company's provider makes any representation or warranty of any
              kind, express or implied: (i) as to the operation or availability
              of the Service, or the information, content, and materials or
              products included thereon; (ii) that the Service will be
              uninterrupted or error-free; (iii) as to the accuracy,
              reliability, or currency of any information or content provided
              through the Service; or (iv) that the Service, its servers, the
              content, or e-mails sent from or on behalf of Lexa are free of
              viruses, scripts, trojan horses, worms, malware, timebombs or
              other harmful components.
            </p>
            <p>
              Company hereby disclaims all warranties of any kind, whether
              express or implied, statutory, or otherwise. The foregoing does
              not affect any warranties which cannot be excluded or limited
              under applicable law.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>
              15. LIMITATION OF LIABILITY
            </h2>

            <p>
              Except as prohibited by law, you will hold us and our officers,
              directors, employees, and agents harmless for any indirect,
              punitive, special, incidental, or consequential damage, however it
              arises (including attorneys’ fees and all related costs and
              expenses of litigation and arbitration, or at trial or on appeal,
              if any, whether or not litigation or arbitration is instituted),
              whether in an action of contract, negligence, or other tortious
              action, or arising out of or in connection with this agreement,
              including without limitation any claim for personal injury or
              property damage, arising from this agreement and any violation by
              you of any federal, state, or local laws, statutes, rules, or
              regulations, even if we have been previously advised of the
              possibility of such damage.{' '}
            </p>
            <p>
              Except as prohibited by law, if there is liability found on the
              part of company, it will be limited to the amount paid for the
              products and/or services, and under no circumstances will there be
              consequential or punitive damages.{' '}
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>16. INDEMNITY</h2>

            <p>
              You agree to indemnify and hold Lexa, and its subsidiaries,
              affiliates, officers, agents, co-branders or other partners, and
              employees, harmless from any claim or demand, including reasonable
              attorneys’ fees, arising out of your use of the Service, your
              connection to the Service, your violation of the Terms of Service,
              or your violation of any rights of another.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>17. TERMINATION</h2>

            <p>
              We may terminate or suspend your account and bar access to Service
              immediately, without prior notice or liability, under our sole
              discretion, for any reason whatsoever and without limitation,
              including but not limited to a breach of Terms.
            </p>
            <p>
              If you wish to terminate your account, you may simply discontinue
              using Service. All provisions of Terms which by their nature
              should survive termination shall survive termination, including,
              without limitation, ownership provisions, warranty disclaimers,
              indemnity and limitations of liability.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>
              18. NON-TRANSFERABILITY OF ACCOUNTS
            </h2>

            <p>
              You agree that your Lexa account is non-transferable except with
              the written consent of Lexa.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>19. GOVERNING LAW</h2>

            <p>
              These Terms shall be governed and construed in accordance with the
              laws of Pakistan, which governing law applies to agreement without
              regard to its conflict of law provisions.
            </p>
            <p>
              If You have any concern or dispute about the Service, you agree to
              first try to resolve the dispute informally by contacting the
              Company. Any legal action, suit, or proceeding arising out of or
              relating to these Terms or your use of Lexa shall be brought
              exclusively in the courts of Pakistan.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>20. CHANGES TO SERVICE</h2>

            <p>
              We reserve the right to withdraw or amend our Service, and any
              service or material we provide via Service, in our sole discretion
              without notice. We will not be liable if for any reason all or any
              part of Service is unavailable at any time or for any period. From
              time to time, we may restrict access to some parts of Service, or
              the entire Service, to users, including registered users.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>21. AMENDMENTS TO TERMS</h2>

            <p>
              We may amend Terms at any time by posting the amended terms on
              this site. It is your responsibility to review these Terms
              periodically..
            </p>
            <p>
              Your continued use of the Platform following the posting of
              revised Terms means that you accept and agree to the changes. You
              are expected to check this page frequently so you are aware of any
              changes, as they are binding on you.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>
              22. WAIVER AND SEVERABILITY
            </h2>

            <p>
              No waiver by Lexa of any term or condition set forth in Terms
              shall be deemed a further or continuing waiver of such term or
              condition or a waiver of any other term or condition, and any
              failure of Lexa to assert a right or provision under Terms shall
              not constitute a waiver of such right or provision.
            </p>
            <p>
              If any provision of Terms is held by a court or other tribunal of
              competent jurisdiction to be invalid, illegal or unenforceable for
              any reason, such provision shall be eliminated or limited to the
              minimum extent such that the remaining provisions of Terms will
              continue in full force and effect.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>23. ACKNOWLEDGEMENT</h2>

            <p>
              By using service or other services provided by us, you acknowledge
              that you have read these terms of service and agree to be bound by
              them. These terms and conditions set out the rights and
              obligations of all users regarding the use of the service. All
              visitors, users and others who access or use the service, thereby,
              agree to be bound and provide acknowledgement to these terms.
            </p>
          </section>
          <section>
            <h2 className='text-lg font-semibold'>24. CONTACT US</h2>

            <p>
              Please send your feedback, comments, requests for technical
              support by email: contact@lexa.lawyer
            </p>
          </section>

          {/* ... other sections ... */}
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
