import React from 'react';
import { Link } from 'react-router-dom'; 
import { LexaLogoHomeIcon } from '../assets/icons';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-6">
            <Link to="/"> <LexaLogoHomeIcon/></Link>
            <div className="max-w-3xl mx-auto bg-white p-6 shadow rounded">
                <div className="mt-8 justify-start flex">
                    <span> <ArrowCircleLeftOutlinedIcon/></span>
                    <Link to="/" className="text-black hover:underline">Go back</Link>
                </div>
                <h1 className="text-3xl font-bold text-center">Privacy Policy - Lexa</h1>
                <div className="space-y-6">
                    <section>
                        <h5 className="text-lg font-semibold">February 7th, 2024</h5>
                        <p>Welcome to Lexa! This Privacy Policy outlines how Lexa collects, uses, and safeguards your personal information as you access legal information and research resources through our platform. By using Lexa, you agree to the terms outlined in this Privacy Policy.</p>
                        <p>This Privacy Policy explains what Personal Information (defined below) we collect from or about Users, how we use and share that information, and your choices concerning our data practices. </p>
                    </section>
                    <section>
                        <h2 className="text-lg font-semibold">Information We Collect:</h2>
                        <h2 className="text-lg font-semibold">1. User Provided Information:</h2>

                        <p>1.1 Lexa may collect personal information that you provide directly and includes information you provide during these interactions, such as name, email address, phone number, physical address or zip code, demographic information (such as your school, gender, age or birthdate), and information about your interests and preferences and professional credentials if applicable.</p>
                        <p>1.2 We may combine the information we collect from you through Services with other Personal Information we have about you as described in this Chatbot Privacy Policy. Any information combined with Personal Information will be treated as Personal Information.</p>
                    </section>
                    <section>
                        <h2 className="text-lg font-semibold">2.Research Data:</h2>

                        <p>2.1 Lexa may collect and store information related to your legal queries, search history, and interactions with the research platform for the purpose of improving our services and enhancing your user experience.</p>
                        <p>2.2 The Usage Information we collect include details of your online interaction with the Services. Usage Information is generally non-identifying, but if we associate it with you as a specific and identifiable person, we treat it as Personal Information.</p>
                    </section>
                    {/* ... other sections ... */}
                    <section>
                        <h2 className="text-lg font-semibold">3. Usage Information:</h2>
                        <p>3.1 Lexa may collect data on your usage patterns, device information, IP address, and the date and time of your interactions to optimize platform performance and security.</p>
                        <p>3.2 We may use information about you, including Personal Information, to: (1) allow you to participate in the Services, including, without limitation, to respond to your questions, complaints or comments; (2) tailor content, recommendations and offers we display to you on the Services; (3) provide you with information, products, services, or suggestions you request; (4) improve the Services and our products, and for internal business purposes, including the improvement of our AI chat algorithms; (5) contact you with regard to your use of the Services and, in our discretion, changes to our policies; and (6) as described in the Chatbot Privacy Policy or as disclosed at the time you provide your information</p>
                    </section>
                    <section>   <h2 className="text-lg font-semibold">4. How We Use Your Information:</h2>
                        <p>4.1 Lexa uses the information provided to conduct legal research and analysis, delivering relevant and accurate content tailored to your queries.</p>
                        <p>4.2 We may use your contact information to communicate important updates, legal research insights, and respond to your inquiries or feedback.</p>
                        <p>4.3 Lexa analyzes user interactions and feedback to enhance the platform's functionality, accuracy, and user interface.</p>
                        <p>4.4 We may disclose information if required to comply with legal obligations, protect our rights, or respond to legal processes.</p>
                        <p>4.5 Lexa does not entertain queries/questions related to self-identity “I” questions, any kind of illegal/illicit queries and does not bear liability for credibility of information provided, professionals shall analyze and use accordingly.</p>

                    </section>
                    <section>
                        <h2 className="text-lg font-semibold">5. Data Security:</h2>

                        <p>5.1 Lexa employs industry-standard security measures to protect your personal and research data against unauthorized access, disclosure, or alteration. </p>
                        <p>5.2 We take data security very seriously and enforce commercially reasonable technical, physical, and administrative security measures to protect the Personal Information submitted to us, both during transmission and once we receive it. </p>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold">6. Information Sharing:</h2>

                        <p>6.1 Lexa does not share your personal information with third parties for marketing purposes. We may share aggregated and anonymized data for research and statistical analysis.</p>
                        <p>6.2 We may share your personal information if you request that we share your personal information, to our partners and service providers, for administrative and legal reasons, business transitions and with your consent. </p>
                        <p>6.3 We may share your information with selected third parties, including: <br />
                            Business partners, vendors, suppliers, and subcontractors who perform services on our behalf (these companies are authorized to use your personal information only as necessary to provide these services to us); Analytics and search engine providers that assist us in the improvement and optimization of our Website; Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you; Payment processors for the purpose of fulfilling relevant payment transactions.</p>

                    </section>


                    <section>
                        <h2 className="text-lg font-semibold">7. Links to third party sites</h2>

                        <p>7.1 The Lexa Service may, from time to time, contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check the individual policies before you submit any information to those websites</p>

                    </section>
                    <section>
                        <h2 className="text-lg font-semibold">8. Your rights</h2>

                        <p>8.1 In this Section, we have summarized the rights that you possess. Your principal rights are: <br />
                            a. the right to access; <br />
                            b. the right to rectification; <br />
                            c. the right to erasure;<br />
                            d. the right to restrict processing;<br />
                            e. the right to object to processing;<br />
                            f. the right to withdraw consent.</p>

                    </section>

                    <section>
                        <h2 className="text-lg font-semibold">9. Consent:</h2>

                        <p>9.1 By using Lexa, you consent to the collection, use, and disclosure of information as described in this Privacy Policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>

                    </section>

                    <section>
                        <h2 className="text-lg font-semibold">10. Changes to Privacy Policy:</h2>

                        <p>10.1 Lexa reserves the right to update this Privacy Policy periodically. Users will be notified of significant changes through the platform or other communication channels.</p>
                        <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to contactus@lexa.lawyer, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement.</p>

                    </section>

                    <section>
                        <h2 className="text-lg font-semibold">11. Contact Us:</h2>

                        <p>11.1 If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at admin@lexa.lawyer <br />
                            Thank you for choosing Lexa for your legal research needs. We are committed to maintaining the confidentiality and security of your information throughout your interactions with our platform.</p>

                    </section>
                    {/* ... other sections ... */}
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
